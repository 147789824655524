import { CircularProgress } from "@mui/material";
import FaceIcon1A from "assets/icons/ic-face01_a.svg";
import NotAvailableImg from "assets/images/no-img-available.png";
import { useEffect, useRef, useState } from "react";
import { drawFacialLaterality } from "services/draw-face-analysis.service";

import HelpModal from "../../FaceScanData/HelpModal";
import ScanLevel from "../../ScanLevel";

interface IFaceClinicProps {
  faceScanResult: any;
  frontImage: string;
  header?: string;
  imgFront: boolean;
  afterComponent?: React.ReactNode;
  onClick?: any;
  frontRealImage?: any;
}

const FacialLaterality = (props: IFaceClinicProps) => {
  const [isLoadingFace, setIsLoadingFace] = useState<boolean>(true);
  const [isOpenHelp, setIsOpenHelp] = useState<boolean>(false);

  //--- Canvas for face
  const canvasFacialLaterality = useRef<HTMLCanvasElement>(null);

  //--- Get data landmark
  const [dataFaceLandmark, setDataFaceLandmark] = useState<any>();

  const getDataLandMark = async () => {
    const landmark =
      props?.faceScanResult?.faceLandmarkResult?.dataFrontFaceLandmark;

    setDataFaceLandmark(landmark);

    setTimeout(() => {
      getDataDenseLandmark();
    }, 2000);
  };

  //--- Get data dense landmark
  const [dataFaceDenseLandmark, setDataFaceDenseLandmark] = useState<any>();

  const getDataDenseLandmark = async () => {
    const faceLandmark = props?.faceScanResult?.faceDenseLandmarkResult;
    setDataFaceDenseLandmark(faceLandmark);
    setIsLoadingFace(false);
  };

  //--- Load data face
  const onLoadDataFace = async () => {
    if (
      props.faceScanResult?.faceLandmarkResult?.dataFrontFaceLandmark &&
      props.faceScanResult?.faceLandmarkResult?.dataFrontFaceRectangle &&
      props.faceScanResult?.faceDenseLandmarkResult
    ) {
      setDataFaceLandmark(
        props.faceScanResult?.faceLandmarkResult?.dataFrontFaceLandmark
      );

      setDataFaceDenseLandmark(props.faceScanResult?.faceDenseLandmarkResult);

      setIsLoadingFace(false);
    } else {
      Promise.all([getDataLandMark()]);
    }
  };

  //--- Handle load image
  const [dimensionsRealImg, setDimensionsRealImg] = useState({
    width: 0,
    height: 0
  });
  const [dimensionsRenderImg, setDimensionsRenderImg] = useState({
    width: 0,
    height: 0
  });

  const getDimensionsRealImg = (url: string, cb: any) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  const handleImageLoad = (e: any) => {
    setTimeout(() => {
      const { width, height } = e.target;

      if (width > 0 && height > 0 && props.frontImage) {
        setDimensionsRenderImg({ width, height });

        getDimensionsRealImg(props.frontImage, (err: any, img: any) => {
          let imgRealWidth = Number(img?.width);
          let imageRealHeight = Number(img?.height);

          setDimensionsRealImg({
            width: imgRealWidth,
            height: imageRealHeight
          });

          if (props.frontImage && imgRealWidth > 0 && imageRealHeight > 0) {
            onLoadDataFace();
          }
        });
      }
    }, 2000);
  };

  useEffect(() => {
    if (
      dataFaceLandmark &&
      dataFaceDenseLandmark &&
      dimensionsRealImg.width > 0 &&
      dimensionsRenderImg.width > 0 &&
      dimensionsRenderImg.height > 0 &&
      props.frontImage
    ) {
      Promise.all([
        //--- Draw facial laterality
        drawFacialLaterality(
          dataFaceLandmark,
          canvasFacialLaterality,
          dimensionsRealImg.width,
          dimensionsRenderImg.width,
          dimensionsRenderImg.height
        )
      ]);
    }
  }, [dataFaceLandmark, dataFaceDenseLandmark]);

  return (
    <>
      <div
        className={`${props.imgFront ? "grid grid-cols-2 gap-4" : "w-full"}`}
      >
        <div className={`${props.imgFront ? "w-fit" : ""}`}>
          <p className="text-purple-500 text-sm">{props.header}</p>
          <div className="relative">
            <div className="w-fit cursor-pointer mx-auto" onClick={props?.onClick}>
              <img
                src={props?.frontRealImage || props?.frontImage}
                alt="balance-face"
                className="max-w-full max-h-full"
                onError={(e: any) => (e.target.src = NotAvailableImg)}
                onLoad={handleImageLoad}
              />

              {/*--- Canvas face ---*/}
              <canvas
                ref={canvasFacialLaterality}
                className={`absolute top-0 left-0 right-0 left-1/2 transform -translate-x-1/2`}
              ></canvas>
            </div>

            <div
              className={`after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-black after:opacity-90 ${isLoadingFace ? "" : "hidden"
                }`}
            ></div>

            <div
              className={`absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 ${isLoadingFace ? "" : "hidden"
                }`}
            >
              <CircularProgress sx={{ color: "#ffffff" }} />
            </div>
          </div>
        </div>
        {props.afterComponent}
      </div>

      {props.imgFront && (
        <div className="mt-4">
          {/*--- Facial laterality ---*/}
          <ScanLevel
            showBefore={false}
            img={FaceIcon1A}
            title="顔左右差"
            onSetOpenHelp={setIsOpenHelp}
          />
        </div>
      )}

      {isOpenHelp && (
        <HelpModal
          isOpen={isOpenHelp}
          closeModal={() => setIsOpenHelp(false)}
          title="顔左右差"
          content={
            <div>
              <p className="font-bold">【アドバイス】</p>
              左右差がある場合、左右差を無くすような施術、メイクがおすすめです
              <br />
              <br />
              例）
              <br />
              眉の位置差：低い方の眉は描き方で少し上げ気味に、高い方はナチュラルな位置をキープ。眉下のハイライトで高さの錯覚も演出可能。
              <br />
              口元の位置差：高い方の口角にシェーディングを、低い方の口角は明るめのカラーで。マッサージは低い方の口角を上に持ち上げるように。
            </div>
          }
        />
      )}
    </>
  );
};

export default FacialLaterality;
