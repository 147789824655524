import { CircularProgress } from "@mui/material";
import FaceIcon4 from "assets/icons/ic-face04.svg";
import NotAvailableImg from "assets/images/no-img-available.png";
import { useEffect, useRef, useState } from "react";
import { drawFaceRatio } from "services/draw-face-analysis.service";
import { calculateTwoRatio, getDistanceOfTwoPoint } from "utils/face-analysis";

import RatioSlider from "components/RatioSlider";

import HelpModal from "../../FaceScanData/HelpModal";
import ScanLevel from "../../ScanLevel";

interface IFaceClinicProps {
  faceScanResult: any;
  frontImage: string;
  header?: string;
  imgFront: boolean;
  afterComponent?: React.ReactNode;
  onClick?: any;
  frontRealImage?: any;
}

const FaceRatio = (props: IFaceClinicProps) => {
  const [isLoadingFace, setIsLoadingFace] = useState<boolean>(true);
  const [isOpenHelp, setIsOpenHelp] = useState<boolean>(false);

  //--- Canvas for face
  const canvasFaceRatio = useRef<HTMLCanvasElement>(null);

  //--- Get data landmark
  const [dataFaceLandmark, setDataFaceLandmark] = useState<any>();

  const getDataLandMark = async () => {
    const landmark =
      props?.faceScanResult?.faceLandmarkResult?.dataFrontFaceLandmark;

    setDataFaceLandmark(landmark);

    setTimeout(() => {
      getDataDenseLandmark();
    }, 2000);
  };

  //--- Get data dense landmark
  const [dataFaceDenseLandmark, setDataFaceDenseLandmark] = useState<any>();

  const getDataDenseLandmark = async () => {
    const faceLandmark = props?.faceScanResult?.faceDenseLandmarkResult;
    setDataFaceDenseLandmark(faceLandmark);
    setIsLoadingFace(false);
  };

  //--- Load data face
  const onLoadDataFace = async () => {
    if (
      props.faceScanResult?.faceLandmarkResult?.dataFrontFaceLandmark &&
      props.faceScanResult?.faceLandmarkResult?.dataFrontFaceRectangle &&
      props.faceScanResult?.faceDenseLandmarkResult
    ) {
      setDataFaceLandmark(
        props.faceScanResult?.faceLandmarkResult?.dataFrontFaceLandmark
      );

      setDataFaceDenseLandmark(props.faceScanResult?.faceDenseLandmarkResult);

      setIsLoadingFace(false);
    } else {
      Promise.all([getDataLandMark()]);
    }
  };

  //--- Face ratio
  const [faceRatio1, setFaceRatio1] = useState<number>(0);
  const [faceRatio2, setFaceRatio2] = useState<number>(0);

  const marksFaceRatio = [
    {
      value: 0
    },
    {
      value: faceRatio1 > 0 && faceRatio1
    },
    {
      value: 100
    }
  ];

  //--- Get data face ratio
  const [scanLevel, setScanLevel] = useState<any>(0);

  const getDataFaceRatio = () => {
    const distinct1 = getDistanceOfTwoPoint(
      dataFaceDenseLandmark?.face?.face_hairline_74,
      dataFaceLandmark.contour_chin
    );

    const distinct2 = getDistanceOfTwoPoint(
      dataFaceLandmark?.contour_right1,
      dataFaceLandmark.contour_left1
    );

    const { ratio1, ratio2 } = calculateTwoRatio(distinct1, distinct2);

    setFaceRatio1(Number(ratio1));
    setFaceRatio2(Number(ratio2));

    const radioResult = Number(Number(ratio1) / Number(ratio2));
    setScanLevel(calculateScanLevel(radioResult));
  };

  //--- Calculate scan level
  const calculateScanLevel = (ratioResult: any) => {
    if (ratioResult < 1.1) return 1;
    if (1.1 <= ratioResult && ratioResult < 1.3) return 2;
    if (1.3 <= ratioResult && ratioResult < 1.5) return 3;
    if (1.5 <= ratioResult && ratioResult < 1.7) return 4;
    if (ratioResult >= 1.7) return 5;
  };

  //--- Handle load image
  const [dimensionsRealImg, setDimensionsRealImg] = useState({
    width: 0,
    height: 0
  });
  const [dimensionsRenderImg, setDimensionsRenderImg] = useState({
    width: 0,
    height: 0
  });

  const getDimensionsRealImg = (url: string, cb: any) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  const handleImageLoad = (e: any) => {
    setTimeout(() => {
      const { width, height } = e.target;

      if (width > 0 && height > 0 && props.frontImage) {
        setDimensionsRenderImg({ width, height });

        getDimensionsRealImg(props.frontImage, (err: any, img: any) => {
          let imgRealWidth = Number(img?.width);
          let imageRealHeight = Number(img?.height);

          setDimensionsRealImg({
            width: imgRealWidth,
            height: imageRealHeight
          });

          if (props.frontImage && imgRealWidth > 0 && imageRealHeight > 0) {
            onLoadDataFace();
          }
        });
      }
    }, 2000);
  };

  useEffect(() => {
    if (
      dataFaceLandmark &&
      dataFaceDenseLandmark &&
      dimensionsRealImg.width > 0 &&
      dimensionsRenderImg.width > 0 &&
      dimensionsRenderImg.height > 0 &&
      props.frontImage
    ) {
      Promise.all([
        //--- Get data face ratio
        getDataFaceRatio(),

        //--- Draw face ratio
        drawFaceRatio(
          dataFaceLandmark,
          dataFaceDenseLandmark,
          canvasFaceRatio,
          dimensionsRealImg.width,
          dimensionsRenderImg.width,
          dimensionsRenderImg.height
        )
      ]);
    }
  }, [dataFaceLandmark, dataFaceDenseLandmark]);

  return (
    <>
      <div
        className={`${props.imgFront ? "grid grid-cols-2 gap-4" : "w-full"}`}
      >
        <div className={`${props.imgFront ? "w-fit" : ""}`}>
          <p className="text-purple-500 text-sm">{props.header}</p>
          <div className="relative">
            <div className="w-fit cursor-pointer mx-auto" onClick={props?.onClick}>
              <img
                src={props?.frontRealImage || props?.frontImage}
                alt="balance-face"
                className="max-w-full max-h-full"
                onError={(e: any) => (e.target.src = NotAvailableImg)}
                onLoad={handleImageLoad}
              />

              {/*--- Canvas face ---*/}
              <canvas
                ref={canvasFaceRatio}
                className={`absolute top-0 left-0 right-0 left-1/2 transform -translate-x-1/2`}
              ></canvas>
            </div>

            <div
              className={`after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-black after:opacity-90 ${
                isLoadingFace ? "" : "hidden"
              }`}
            ></div>

            <div
              className={`absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 ${
                isLoadingFace ? "" : "hidden"
              }`}
            >
              <CircularProgress sx={{ color: "#ffffff" }} />
            </div>
          </div>
        </div>
        {props.afterComponent}
      </div>

      {props.imgFront && (
        <div className="mt-4">
          {/*--- Face Ratio ---*/}
          <ScanLevel
            img={FaceIcon4}
            title="縦横比 (1.4:1)"
            point={scanLevel}
            pointNames={["横長", "平均顔", "縦長"]}
            measure={
              faceRatio1 > 0 &&
              faceRatio2 > 0 && (
                <div>
                  <div className="clinic-proposal-custom-ratio">
                    <RatioSlider marksArr={marksFaceRatio} />
                  </div>
                  <div className="flex relative text-xs text-black">
                    <span
                      className="absolute"
                      style={{
                        top: "-10px",
                        left: `calc((${Math.ceil(faceRatio1)}% / 2) - 1rem)`
                      }}
                    >
                      縦: {faceRatio1}
                    </span>
                    <span
                      className="absolute"
                      style={{
                        top: "-10px",
                        left: `calc((${Math.ceil(faceRatio1)}% + (${Math.ceil(
                          faceRatio2
                        )}% / 2)) - 1rem)`
                      }}
                    >
                      横: {faceRatio2}
                    </span>
                  </div>
                </div>
              )
            }
            onSetOpenHelp={setIsOpenHelp}
          />
        </div>
      )}

      {isOpenHelp && (
        <HelpModal
          isOpen={isOpenHelp}
          closeModal={() => setIsOpenHelp(false)}
          title="縦横比"
          content={
            <div>
              <p className="font-bold">【黄金比】</p>
              縦:横 = 1.4:1
              <br />
              <br />
              <p className="font-bold">【アドバイス】</p>
              縦長の場合や横長の場合に、理想に近づける髪型や施術を提案できます。
              <br />
              <br />
              例）
              <br />{" "}
              縦長タイプ：横への広がりを意識したスタイリングで、バランスの取れた印象に。横方向の動きやボリュームで、縦のラインを自然にカバーできます。
              <br />
              <br />
              標準タイプ：全体的なバランスが整っているため、様々なヘアデザインを楽しめます。好みや雰囲気に合わせて自由なアレンジが可能です。
              <br />
              <br />
              横長タイプ：縦のラインを強調するスタイリングで、すっきりとした印象に。上方向への動きやボリュームで、自然なバランスを演出できます。
              <br />
              <br />
              これらは基本的な方向性であり、個人の特徴や好みに合わせて調整することで、より自然な仕上がりが期待できます。
            </div>
          }
        />
      )}
    </>
  );
};

export default FaceRatio;
