import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ROUTE } from "constants/paths";
import { NotifyTypeEnum } from "enums/notify";
import { useNavigate } from "react-router";
import { notify } from "utils/notify";

import { deleteApi, getApi, postApi, putApi } from "./common/api";
import { API_URL } from "./common/path";

export const useMessageListQuery = (params = {}) => {
  return useQuery(
    ["get_messages", params],
    async () => {
      try {
        const { data } = await getApi(API_URL.MESSAGE.LIST, params);
        return { ...data };
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};

export const useMessageDetailQuery = (id: string) => {
  return useQuery(
    ["get_message_detail", id],
    async () => {
      try {
        if (!id) return;
        const { data } = await getApi(
          API_URL.MESSAGE.DETAIL.replace(":id", id),
          {}
        );
        return { ...data };
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};

export const useTargetUserListQuery = (params = {}) => {
  return useQuery(
    ["get_targetUser", params],
    async () => {
      try {
        const { data } = await getApi(API_URL.CUSTOMERS.LIST, params);
        return { ...data };
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};

export const useAddMessage = ({ onLimitMess }: any) => {
  const navigate = useNavigate();
  return useMutation(
    (newMessage) => {
      return postApi(API_URL.MESSAGE.CREATE, newMessage);
    },
    {
      onSuccess: () => {
        notify("メッセージが追加されました", NotifyTypeEnum.SUCCESS);
        navigate(ROUTE.MESSAGE.INDEX);
      },
      onError: (error: any) => {
        const errRes = error?.response?.data;
        if (errRes?.error_type === 'limit_message') {
          onLimitMess(errRes?.error);
          return;
        }
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR);
      }
    }
  );
};

export const useUpdateMessage = (id: string) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(
    (newMessage) => {
      return putApi(API_URL.MESSAGE.EDIT.replace(":id", id), newMessage);
    },
    {
      onSuccess: () => {
        notify("メッセージが追加されました", NotifyTypeEnum.SUCCESS);
        navigate(ROUTE.MESSAGE.INDEX);
        queryClient.invalidateQueries(["get_message_detail", id]);
      },
      onError: (error: any) => {
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR);
      }
    }
  );
};

export const useCustomerMessageListQuery = (params: any = {}, id: string) => {
  return useQuery(
    ["get_customer_messages", id, params],
    async () => {
      try {
        const { data } = await getApi(
          API_URL.MESSAGE.CUSTOMER_MESSAGE.replace(":id", id),
          params
        );
        return { ...data };
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};

export const useCustomerMessageLineListQuery = (params: any = {}) => {
  return useQuery(
    ["get_customer_messages_line", params],
    async () => {
      try {
        const { data } = await getApi(API_URL.MESSAGE.LINE_MESSAGE, params);
        return { ...data };
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};
