import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import EyeBrowClinic from "./EyeBrowClinic";
import EyeClinic from "./EyeClinic";
// import FaceClinic from "./FaceClinic";
import LowerHalfFaceClinic from "./LowerHalfFaceClinic";
import MouthClinic from "./MouthClinic";
import NoImage from "./NoImage";
import NoseClinic from "./NoseClinic";
import SideFaceClinic from "./SideFaceClinic";

interface IFaceScanDataProps {
  faceAnalysisData: any;
  dataFaceLandmark: any;
  dataFaceDenseLandmark: any;
  faceRectangle: any;
  frontImgUrl: any;
  frontRealImgUrl: any;
  dataFaceLandmarkAfter: any;
  dataFaceDenseLandmarkAfter: any;
  faceRectangleAfter: any;
  afterFrontImgUrl: any;
  afterRealImgUrl: any;
  frontSideImgUrl: any;
  afterSideImgUrl: any;
  activeTab: number;
  customer_id?: any;
  echart_id?: any;
  frontSideRealImgUrl?: any;
  afterSideRealImgUrl?: any;
}

const FaceScanBeforeAfter: React.FC<IFaceScanDataProps> = ({
  faceAnalysisData,
  dataFaceLandmark,
  dataFaceDenseLandmark,
  faceRectangle,
  frontImgUrl,
  frontRealImgUrl,
  dataFaceLandmarkAfter,
  dataFaceDenseLandmarkAfter,
  faceRectangleAfter,
  afterFrontImgUrl,
  afterRealImgUrl,
  frontSideImgUrl,
  afterSideImgUrl,
  activeTab,
  customer_id,
  echart_id,
  frontSideRealImgUrl,
  afterSideRealImgUrl
}) => {
  const navigate = useNavigate();

  //--- State
  const [isLoadingLowerHalf, setIsLoadingLowerHalf] = useState(false);
  const [isLoadingEye, setIsLoadingEye] = useState(false);
  const [isLoadingEyeBrow, setIsLoadingEyeBrow] = useState(false);
  const [isLoadingNose, setIsLoadingNose] = useState(false);
  const [isLoadingMouth, setIsLoadingMouth] = useState(false);
  // const [isLoadingSkin, setIsLoadingSkin] = useState(false);

  // useEffect(() => {
  //   fetch(frontImgUrl)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const url = URL.createObjectURL(blob);
  //       setFrontImgBlob(url);
  //     });
  // }, [frontImgUrl]);

  // useEffect(() => {
  //   fetch(afterFrontImgUrl)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const url = URL.createObjectURL(blob);
  //       setAfterFrontImgBlob(url);
  //     });
  // }, [afterFrontImgUrl]);

  useEffect(() => {
    window.onpopstate = (e) => {
      navigate(0);
    };
  }, []);

  return (
    <div className="relative z-100 bg-gray-100">
      <div className="z-100 space-y-4" id="fullClinic">
        {/* <div className="rounded-2xl bg-white hidden">
          {faceAnalysisData && frontImgUrl && (
            <FaceClinic
              customerId={customerId}
              faceScanResult={faceAnalysisData?.front_image}
              frontImage={frontImgUrl}
              faceMenu={undefined}
              menuMetaData={undefined}
              header={"Before"}
              onSetDataFaceLandmark={(dataFaceLandmark: any) =>
                setDataFaceLandmark(dataFaceLandmark)
              }
              onSetDataFaceDenseLandmark={(dataFaceDenseLandmark: any) =>
                setDataFaceDenseLandmark(dataFaceDenseLandmark)
              }
              onSetFaceRectangle={(faceRectangle: any) =>
                setFaceRectangle(faceRectangle)
              }

              setIsLoadingFace={setIsLoadingFace}
              isLoadingFace={isLoadingFace}
              isDisable={isDisable}
              imgFront={true}
              afterComponent={
                faceAnalysisData && afterFrontImgUrl ? (
                  <div className="w-1/2">
                    <FaceClinic
                      customerId={customerId}
                      faceScanResult={faceAnalysisData?.after_front_image}
                      frontImage={afterFrontImgUrl}
                      faceMenu={undefined}
                      menuMetaData={undefined}
                      header={"After"}
                      onSetDataFaceLandmark={(dataFaceLandmark: any) =>
                        setDataFaceLandmarkAfter(dataFaceLandmark)
                      }
                      onSetDataFaceDenseLandmark={(
                        dataFaceDenseLandmark: any
                      ) => setDataFaceDenseLandmarkAfter(dataFaceDenseLandmark)}
                      onSetFaceRectangle={(faceRectangle: any) =>
                        setFaceRectangleAfter(faceRectangle)
                      }

                      setIsLoadingFace={setIsLoadingFace}
                      isLoadingFace={isLoadingFace}
                      isDisable={isDisable}
                      imgFront={false}
                    />
                  </div>
                ) : (
                  <div className="w-1/2">
                    <NoImage header={"After"} />
                  </div>
                )
              }
            />
          )}
        </div> */}

        <>
          {[0, 3].includes(activeTab) && (
            <div className="rounded-2xl bg-white p-2 w-fit mx-auto">
              <div className="text-2xl font-bold mb-2">顔下半分</div>
              {faceAnalysisData &&
              dataFaceLandmark &&
              dataFaceDenseLandmark &&
              faceRectangle &&
              frontImgUrl ? (
                <LowerHalfFaceClinic
                  dataFaceLandmark={dataFaceLandmark}
                  dataFaceDenseLandmark={dataFaceDenseLandmark}
                  faceRectangle={faceRectangle}
                  frontImage={frontImgUrl}
                  frontRealImgUrl={frontRealImgUrl}
                  header={"Before"}
                  element={"lowerHalfFaceBeforeEl"}
                  setIsLoadingLowerHalf={setIsLoadingLowerHalf}
                  isLoadingLowerHalf={isLoadingLowerHalf}
                  afterComponent={
                    faceAnalysisData &&
                    dataFaceLandmarkAfter &&
                    dataFaceDenseLandmarkAfter &&
                    faceRectangleAfter &&
                    afterFrontImgUrl ? (
                      <LowerHalfFaceClinic
                        dataFaceLandmark={dataFaceLandmarkAfter}
                        dataFaceDenseLandmark={dataFaceDenseLandmarkAfter}
                        faceRectangle={faceRectangleAfter}
                        frontImage={afterFrontImgUrl}
                        frontRealImgUrl={afterRealImgUrl}
                        header={"After"}
                        element={"lowerHalfFaceAfterEl"}
                        setIsLoadingLowerHalf={setIsLoadingLowerHalf}
                        isLoadingLowerHalf={isLoadingLowerHalf}
                      />
                    ) : (
                      <NoImage header={"After"} />
                    )
                  }
                />
              ) : (
                <div className="text-center text-sm text-gray-500 mt-4 pb-4">
                  データなし
                </div>
              )}
            </div>
          )}

          {[0, 4].includes(activeTab) && (
            <div className="rounded-2xl bg-white p-2 w-fit mx-auto">
              <div className="text-2xl font-bold mb-2">目</div>
              {faceAnalysisData &&
              dataFaceLandmark &&
              dataFaceDenseLandmark &&
              frontImgUrl ? (
                <EyeClinic
                  dataFaceLandmark={dataFaceLandmark}
                  dataFaceDenseLandmark={dataFaceDenseLandmark}
                  faceRectangle={faceRectangle}
                  frontImage={frontImgUrl}
                  frontRealImgUrl={frontRealImgUrl}
                  header={"Before"}
                  element={"eyeBeforeEl"}
                  setIsLoadingEye={setIsLoadingEye}
                  isLoadingEye={isLoadingEye}
                  afterComponent={
                    faceAnalysisData &&
                    dataFaceLandmarkAfter &&
                    dataFaceDenseLandmarkAfter &&
                    afterFrontImgUrl ? (
                      <EyeClinic
                        dataFaceLandmark={dataFaceLandmarkAfter}
                        dataFaceDenseLandmark={dataFaceDenseLandmarkAfter}
                        faceRectangle={faceRectangleAfter}
                        frontImage={afterFrontImgUrl}
                        frontRealImgUrl={afterRealImgUrl}
                        header={"After"}
                        element={"eyeAfterEl"}
                        setIsLoadingEye={setIsLoadingEye}
                        isLoadingEye={isLoadingEye}
                      />
                    ) : (
                      <NoImage header={"After"} />
                    )
                  }
                />
              ) : (
                <div className="text-center text-sm text-gray-500 mt-4 pb-4">
                  データなし
                </div>
              )}
            </div>
          )}

          {[0, 5].includes(activeTab) && (
            <div className="rounded-2xl bg-white p-2 w-fit mx-auto">
              <div className="text-2xl font-bold mb-2">眉</div>
              {faceAnalysisData &&
              dataFaceLandmark &&
              dataFaceDenseLandmark &&
              frontImgUrl ? (
                <EyeBrowClinic
                  dataFaceLandmark={dataFaceLandmark}
                  dataFaceDenseLandmark={dataFaceDenseLandmark}
                  faceRectangle={faceRectangle}
                  frontImage={frontImgUrl}
                  frontRealImgUrl={frontRealImgUrl}
                  header={"Before"}
                  element={"eyeBrowBeforeEl"}
                  setIsLoadingEyeBrow={setIsLoadingEyeBrow}
                  isLoadingEyeBrow={isLoadingEyeBrow}
                  afterComponent={
                    faceAnalysisData &&
                    dataFaceLandmarkAfter &&
                    dataFaceDenseLandmarkAfter &&
                    afterFrontImgUrl ? (
                      <EyeBrowClinic
                        dataFaceLandmark={dataFaceLandmarkAfter}
                        dataFaceDenseLandmark={dataFaceDenseLandmarkAfter}
                        faceRectangle={faceRectangleAfter}
                        frontImage={afterFrontImgUrl}
                        frontRealImgUrl={afterRealImgUrl}
                        header={"After"}
                        element={"eyeBrowAfterEl"}
                        setIsLoadingEyeBrow={setIsLoadingEyeBrow}
                        isLoadingEyeBrow={isLoadingEyeBrow}
                      />
                    ) : (
                      <NoImage header={"After"} />
                    )
                  }
                />
              ) : (
                <div className="text-center text-sm text-gray-500 mt-4 pb-4">
                  データなし
                </div>
              )}
            </div>
          )}

          {[0, 6].includes(activeTab) && (
            <div className="rounded-2xl bg-white p-2 w-fit mx-auto">
              <div className="text-2xl font-bold mb-2">鼻</div>
              {faceAnalysisData &&
              dataFaceLandmark &&
              dataFaceDenseLandmark &&
              frontImgUrl ? (
                <NoseClinic
                  faceScanResult={faceAnalysisData?.front_image}
                  dataFaceLandmark={dataFaceLandmark}
                  dataFaceDenseLandmark={dataFaceDenseLandmark}
                  faceRectangle={faceRectangle}
                  frontImage={frontImgUrl}
                  frontRealImgUrl={frontRealImgUrl}
                  header={"Before"}
                  element={"noseBeforeEl"}
                  setIsLoadingNose={setIsLoadingNose}
                  isLoadingNose={isLoadingNose}
                  afterComponent={
                    faceAnalysisData &&
                    dataFaceLandmarkAfter &&
                    dataFaceDenseLandmarkAfter &&
                    afterFrontImgUrl ? (
                      <NoseClinic
                        faceScanResult={faceAnalysisData?.after_front_image}
                        dataFaceLandmark={dataFaceLandmarkAfter}
                        dataFaceDenseLandmark={dataFaceDenseLandmarkAfter}
                        faceRectangle={faceRectangleAfter}
                        frontImage={afterFrontImgUrl}
                        frontRealImgUrl={afterRealImgUrl}
                        header={"After"}
                        element={"noseAfterEl"}
                        setIsLoadingNose={setIsLoadingNose}
                        isLoadingNose={isLoadingNose}
                      />
                    ) : (
                      <NoImage header={"After"} />
                    )
                  }
                />
              ) : (
                <div className="text-center text-sm text-gray-500 mt-4 pb-4">
                  データなし
                </div>
              )}
            </div>
          )}

          {[0, 7].includes(activeTab) && (
            <div className="rounded-2xl bg-white p-2 w-fit mx-auto">
              <div className="text-2xl font-bold mb-2">口</div>
              {faceAnalysisData &&
              dataFaceLandmark &&
              dataFaceDenseLandmark &&
              frontImgUrl ? (
                <MouthClinic
                  dataFaceLandmark={dataFaceLandmark}
                  dataFaceDenseLandmark={dataFaceDenseLandmark}
                  faceRectangle={faceRectangle}
                  frontImage={frontImgUrl}
                  frontRealImgUrl={frontRealImgUrl}
                  header={"Before"}
                  element={"mouthBeforeEl"}
                  setIsLoadingMouth={setIsLoadingMouth}
                  isLoadingMouth={isLoadingMouth}
                  afterComponent={
                    faceAnalysisData &&
                    dataFaceLandmarkAfter &&
                    dataFaceDenseLandmarkAfter &&
                    afterFrontImgUrl ? (
                      <MouthClinic
                        dataFaceLandmark={dataFaceLandmarkAfter}
                        dataFaceDenseLandmark={dataFaceDenseLandmarkAfter}
                        faceRectangle={faceRectangleAfter}
                        frontImage={afterFrontImgUrl}
                        frontRealImgUrl={afterRealImgUrl}
                        header={"After"}
                        element={"mouthAfterEl"}
                        setIsLoadingMouth={setIsLoadingMouth}
                        isLoadingMouth={isLoadingMouth}
                      />
                    ) : (
                      <NoImage header={"After"} />
                    )
                  }
                />
              ) : (
                <div className="text-center text-sm text-gray-500 mt-4 pb-4">
                  データなし
                </div>
              )}
            </div>
          )}

          {[0, 8].includes(activeTab) && (
            <div className="rounded-2xl bg-white p-2 w-fit mx-auto">
              <div className="text-2xl font-bold mb-2">横顔</div>
              {faceAnalysisData && frontSideImgUrl && (
                <SideFaceClinic
                  faceAnalysisResult={
                    faceAnalysisData?.front_image?.faceAnalysisResult
                  }
                  sideImage={frontSideImgUrl}
                  frontRealImgUrl={frontSideRealImgUrl}
                  header={"Before"}
                  customer_id={customer_id}
                  echart_id={echart_id}
                  isReport={false}
                  afterComponent={
                    faceAnalysisData &&
                    afterSideImgUrl && (
                      <SideFaceClinic
                        faceAnalysisResult={
                          faceAnalysisData?.after_front_image
                            ?.faceAnalysisResult
                        }
                        sideImage={afterSideImgUrl}
                        frontRealImgUrl={afterSideRealImgUrl}
                        header={"After"}
                        customer_id={customer_id}
                        echart_id={echart_id}
                        isReport={false}
                      />
                    )
                  }
                />
              )}

              {!frontSideImgUrl && !afterSideImgUrl && (
                <div className="grid grid-cols-2 gap-4">
                  <NoImage header={"Before"} />
                  <NoImage header={"After"} />
                </div>
              )}
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default FaceScanBeforeAfter;
