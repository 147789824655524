import {ISelectOption} from "../types/common";

const PREFIX_APP = "FACE_ANALYSIS_APP_";

const LOCAL_STORAGE_KEY = {
  ACCESS_TOKEN: PREFIX_APP + "ACCESS_TOKEN",
  USER_ID: PREFIX_APP + "USER_ID",
  USER_INFO: PREFIX_APP + "USER_INFO",
  COMPANY_INFO: PREFIX_APP + "COMPANY_INFO",
  PREVIOUS_PAGE_CUS_LIST: PREFIX_APP + "PREVIOUS_PAGE_CUS_LIST",
  USER_CREATE_ID: "CREATE_USER_ID",
  PAYMENT_STATUS: 'PAYMENT_STATUS'
};

const PREFECTURE_SELECT_OPTION: ISelectOption[] = [
  {label: "北海道", value: "北海道"},
  {label: "青森県", value: "青森県"},
  {label: "岩手県", value: "岩手県"},
  {label: "宮城県", value: "宮城県"},
  {label: "秋田県", value: "秋田県"},
  {label: "山形県", value: "山形県"},
  {label: "福島県", value: "福島県"},
  {label: "茨城県", value: "茨城県"},
  {label: "栃木県", value: "栃木県"},
  {label: "群馬県", value: "群馬県"},
  {label: "埼玉県", value: "埼玉県"},
  {label: "千葉県", value: "千葉県"},
  {label: "東京都", value: "東京都"},
  {label: "神奈川県", value: "神奈川県"},
  {label: "新潟県", value: "新潟県"},
  {label: "富山県", value: "富山県"},
  {label: "石川県", value: "石川県"},
  {label: "福井県", value: "福井県"},
  {label: "山梨県", value: "山梨県"},
  {label: "長野県", value: "長野県"},
  {label: "岐阜県", value: "岐阜県"},
  {label: "静岡県", value: "静岡県"},
  {label: "愛知県", value: "愛知県"},
  {label: "三重県", value: "三重県"},
  {label: "滋賀県", value: "滋賀県"},
  {label: "京都府", value: "京都府"},
  {label: "大阪府", value: "大阪府"},
  {label: "兵庫県", value: "兵庫県"},
  {label: "奈良県", value: "奈良県"},
  {label: "和歌山県", value: "和歌山県"},
  {label: "鳥取県", value: "鳥取県"},
  {label: "島根県", value: "島根県"},
  {label: "岡山県", value: "岡山県"},
  {label: "広島県", value: "広島県"},
  {label: "山口県", value: "山口県"},
  {label: "徳島県", value: "徳島県"},
  {label: "香川県", value: "香川県"},
  {label: "愛媛県", value: "愛媛県"},
  {label: "高知県", value: "高知県"},
  {label: "福岡県", value: "福岡県"},
  {label: "佐賀県", value: "佐賀県"},
  {label: "長崎県", value: "長崎県"},
  {label: "熊本県", value: "熊本県"},
  {label: "大分県", value: "大分県"},
  {label: "宮崎県", value: "宮崎県"},
  {label: "鹿児島県", value: "鹿児島県"},
  {label: "沖縄県", value: "沖縄県"}
];

const CAMERA = {
  FACING_MODE: PREFIX_APP + "FACING_MODE"
};

const FACE_TYPES = {
  FEMALE: "フェイスタイプ（女性）",
  MALE: "フェイスタイプ（男性）"
};

export const VISIT_DATE_OPTIONS = [
  {value: "1_month", label: "1ヶ月以内"},
  {value: "2_months", label: "2ヶ月以内"},
  {value: "3_months", label: "3ヶ月以内"},
  {value: "4_months", label: "4ヶ月以内"},
  {value: "5_months", label: "5ヶ月以内"},
  {value: "6_months", label: "6ヶ月以内"},
  {value: "1_year", label: "1年以内"},
  {value: "2_years", label: "2年以内"},
  {value: "3_years", label: "3年以内"},
  {value: "over_3_years", label: "3年以上前"}
];

export {LOCAL_STORAGE_KEY, PREFECTURE_SELECT_OPTION, CAMERA, FACE_TYPES};

export const PRICE = {
  standard: 30000,
  user: 500,
  ratio: 18000
};

export const PLAN_TYPE = {
  standard: 'standard',
  ratio: 'ratio'
};

export const PLAN_LIST = [
  {label: "スタンダード（全機能利用可能）", value: "standard"},
  {label: "ライト（フェイススキャン診断Ⓡなし）", value: "ratio"}
];

export const PLAN_KEY =
{
  standard: {
    label: 'スタンダード（全機能利用可能）',
    value: "standard"
  },
  ratio: {
    label: 'ライト（フェイススキャン診断Ⓡなし）',
    value: "ratio"
  }
};
