import { CircularProgress } from "@mui/material";
import EyeBrowIcon1 from "assets/icons/ic-eyebrows01.svg";
import EyeBrowIcon2 from "assets/icons/ic-eyebrows02.svg";
import EyeBrowIcon3 from "assets/icons/ic-eyebrows03.svg";
import EyeBrowIcon4 from "assets/icons/ic-eyebrows04.svg";
import EyeBrowIcon5 from "assets/icons/ic-eyebrows05.svg";
import EyeBrowClinicImg from "assets/images/img-eyebrow-clinic.png";
import NotAvailableImg from "assets/images/no-img-available.png";
// import { domToPng } from "modern-screenshot";
import { useRef, useState } from "react";
import {
  drawEyeBrowAngle,
  drawEyeBrowContour,
  drawEyeBrowFirstCorner,
  drawEyeBrowQuarter,
  drawEyeBrowSecondCorner // drawGoldenRatio
} from "services/draw-face-analysis.service";
import {
  calculateAngle // getLeftTriangleAngle,
  // getRightTriangleAngle
} from "utils/face-analysis";

import HelpModal from "../FaceScanData/HelpModal";
import ScanLevel from "../ScanLevel";
import DetailModal from './DetailModal'

interface IEyeBrowClinicProps {
  dataFaceLandmark: any;
  dataFaceDenseLandmark: any;
  faceRectangle: any;
  frontImage: string;
  header: string;
  element: string;
  setIsLoadingEyeBrow: any;
  isLoadingEyeBrow: boolean;
  afterComponent?: React.ReactNode;
  frontRealImgUrl?: any
}

const EyeBrowClinic = (props: IEyeBrowClinicProps) => {
  // const isShowSection =
  //   props?.faceScanResult?.faceAnalysisResult?.checkedEyeBrowFeature;

  const frontImg = props?.frontImage;
  const frontRealImgUrl = props?.frontRealImgUrl;

  const [isOpenHelp, setIsOpenHelp] = useState<boolean>(false);
  const [showModalDetail, setShowModalDetail] = useState<boolean>(false);
  const imageRef = useRef(null);

  //--- Canvas for eye brow
  const canvasEyeBrowContour = useRef<HTMLCanvasElement>(null);
  const canvasEyeBrowAngle = useRef<HTMLCanvasElement>(null);
  const canvasEyeBrowFirstCorner = useRef<HTMLCanvasElement>(null);
  const canvasEyeBrowQuarter = useRef<HTMLCanvasElement>(null);
  const canvasEyeBrowSecondCorner = useRef<HTMLCanvasElement>(null);
  // const canvasGoldenRatio = useRef<HTMLCanvasElement>(null);

  //--- Get data eye brow angle
  const [eyeBrowLeftAngle, setEyeBrowLeftAngle] = useState<number>(0);
  const [eyeBrowRightAngle, setEyeBrowRightAngle] = useState<number>(0);
  const [scanLevel, setScanLevel] = useState<number>(0);

  const getDataEyeBrowAngle = () => {
    if (props.dataFaceLandmark) {
      // const angleLeft = getLeftTriangleAngle(
      //   props.dataFaceLandmark.left_eyebrow_left_corner,
      //   props.dataFaceLandmark.left_eyebrow_upper_left_quarter
      // );

      const angleLeft = calculateAngle(
        props.dataFaceLandmark.left_eyebrow_upper_left_quarter,
        props.dataFaceLandmark.left_eyebrow_lower_right_quarter,
        props.dataFaceLandmark.left_eyebrow_right_corner
      );

      // const angleRight = getRightTriangleAngle(
      //   props.dataFaceLandmark.right_eyebrow_right_corner,
      //   props.dataFaceLandmark.right_eyebrow_upper_right_quarter
      // );

      const angleRight = calculateAngle(
        props.dataFaceLandmark.right_eyebrow_upper_right_quarter,
        props.dataFaceLandmark.right_eyebrow_lower_left_quarter,
        props.dataFaceLandmark.right_eyebrow_left_corner
      );

      setEyeBrowLeftAngle(Number(Math.abs(angleLeft).toFixed(0)));
      setEyeBrowRightAngle(Number(Math.abs(angleRight).toFixed(0)));

      setScanLevel(calculateScanLevel(Number(Math.abs(angleRight).toFixed(0))));
    }
  };

  //--- Calculate scan level
  const calculateScanLevel = (ratioResult: any) => {
    if (ratioResult < -15) return 1;
    if (ratioResult >= -15 && ratioResult < -5) return 2;
    if (ratioResult >= -5 && ratioResult < 5) return 3;
    if (ratioResult >= 5 && ratioResult < 15) return 4;
    return 5;
  };

  //--- Generate image
  // const [eyeBrowImgCrop, setEyeBrowImgCrop] = useState<any>();

  // const generateImage = async (
  //   imgRealWidth: number,
  //   imgRenderWidth: number
  // ) => {
  //   props.setIsLoadingEyeBrow(true);

  //   const imageScale = imgRealWidth / imgRenderWidth;

  //   setTimeout(async () => {
  //     var eyeBrowNode = document.getElementById(props.element);

  //     if (eyeBrowNode === null) {
  //       return;
  //     }

  //     await domToPng(eyeBrowNode).then(async (dataUrl: any) => {
  //       if (dataUrl) {
  //         if (eyeBrowNode === null) {
  //           return;
  //         }

  //         await domToPng(eyeBrowNode).then((dataUrl1: any) => {
  //           if (dataUrl1) {
  //             cropImage(
  //               dataUrl1,
  //               0,
  //               Math.floor(props.faceRectangle?.top / imageScale) - 8,
  //               imgRenderWidth,
  //               Math.floor(props.faceRectangle?.height / imageScale)
  //               // Math.floor(
  //               //   props.dataFaceLandmark?.contour_left1?.x / imageScale
  //               // ),
  //               // Math.floor(
  //               //   props.dataFaceLandmark?.right_eyebrow_upper_right_quarter?.y /
  //               //     imageScale
  //               // ) - 25,
  //               // Math.floor(
  //               //   props.dataFaceLandmark?.contour_right1?.x / imageScale
  //               // ) -
  //               //   Math.floor(
  //               //     props.dataFaceLandmark?.contour_left1?.x / imageScale
  //               //   ),
  //               // Math.floor(
  //               //   props.dataFaceLandmark?.contour_right4?.y / imageScale
  //               // ) +
  //               //   25 -
  //               //   Math.floor(
  //               //     props.dataFaceLandmark?.right_eyebrow_upper_right_quarter
  //               //       ?.y / imageScale
  //               //   )
  //             );
  //           }
  //         });
  //       }
  //     });

  //     props.setIsLoadingEyeBrow(false);
  //   }, 6000);
  // };

  // const cropImage = (
  //   imageUrl: any,
  //   cropX: any,
  //   cropY: any,
  //   cropWidth: any,
  //   cropHeight: any
  // ) => {
  //   const canvas = document.createElement("canvas");

  //   if (canvas && imageUrl) {
  //     const image = new Image();

  //     image.onload = () => {
  //       canvas.width = cropWidth;
  //       canvas.height = cropHeight;

  //       const ctxCropImage = canvas.getContext("2d");

  //       if (ctxCropImage)
  //         ctxCropImage.drawImage(
  //           image,
  //           cropX,
  //           cropY,
  //           cropWidth,
  //           cropHeight,
  //           0,
  //           0,
  //           cropWidth,
  //           cropHeight
  //         );

  //       const dataUrl = canvas.toDataURL();

  //       setEyeBrowImgCrop(dataUrl);
  //     };

  //     image.setAttribute("crossorigin", "anonymous");
  //     image.src = imageUrl;
  //   }
  // };

  //--- Handle load image
  const getDimensionsRealImg = (url: string, cb: any) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  const handleImageLoad = (e: any) => {
    props.setIsLoadingEyeBrow(true);

    const { width, height } = e.target;

    getDimensionsRealImg(frontImg, (err: any, img: any) => {
      let imgRealWidth = Number(img?.width);

      if (
        imgRealWidth > 0 &&
        width > 0 &&
        height > 0 &&
        props?.dataFaceLandmark &&
        props?.dataFaceDenseLandmark &&
        frontImg
      ) {
        Promise.all([
          //--- Draw eye brow contour
          drawEyeBrowContour(
            props?.dataFaceDenseLandmark,
            canvasEyeBrowContour,
            imgRealWidth,
            width,
            height
          ),
          //--- Draw eye brow angle
          drawEyeBrowAngle(
            props?.dataFaceLandmark,
            canvasEyeBrowAngle,
            imgRealWidth,
            width,
            height
          ),
          //--- Draw eye brow first corner
          drawEyeBrowFirstCorner(
            props?.dataFaceLandmark,
            canvasEyeBrowFirstCorner,
            imgRealWidth,
            width,
            height
          ),
          //--- Draw eye brow quarter
          drawEyeBrowQuarter(
            props?.dataFaceLandmark,
            canvasEyeBrowQuarter,
            imgRealWidth,
            width,
            height
          ),
          //--- Draw eye brow second corner
          drawEyeBrowSecondCorner(
            props?.dataFaceLandmark,
            canvasEyeBrowSecondCorner,
            imgRealWidth,
            width,
            height
          ),
          //--- Draw golden ratio
          // drawGoldenRatio(props?.dataFaceLandmark, canvasGoldenRatio, imgRealWidth, width, height),
          //--- Get data eye borrow angle
          getDataEyeBrowAngle(),

          props.setIsLoadingEyeBrow(false)
          //--- Generate image
          // generateImage(imgRealWidth, width)
        ]);
      }
    });
  };

  return (
    <>
      <div
        className={`${props.header === "Before" ? "grid grid-cols-2 gap-4" : "w-full"}`}
      >
        {frontImg && <DetailModal isOpen={showModalDetail} closeModal={() => setShowModalDetail(false)} >
          <div className="relative" >
            <div className="w-fit" id={props.element}>
              {frontImg && (
                <>
                  <img
                    src={frontRealImgUrl || frontImg}
                    alt="face-img"
                    className="max-w-full max-h-full"
                    onError={(e: any) => (e.target.src = NotAvailableImg)}
                    onLoad={handleImageLoad}
                  />

                  {/*--- Canvas eye brow ---*/}
                  <canvas
                    ref={canvasEyeBrowContour}
                    className={`absolute top-0 left-0 right-0`}
                  />

                  <canvas
                    ref={canvasEyeBrowAngle}
                    className={`absolute top-0 left-0 right-0`}
                  />

                  <canvas
                    ref={canvasEyeBrowFirstCorner}
                    className={`absolute top-0 left-0 right-0`}
                  />

                  <canvas
                    ref={canvasEyeBrowQuarter}
                    className={`absolute top-0 left-0 right-0`}
                  />

                  <canvas
                    ref={canvasEyeBrowSecondCorner}
                    className={`absolute top-0 left-0 right-0`}
                  />
                </>
              )}
            </div>

            <div
              className={`after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-black after:opacity-90 ${props.isLoadingEyeBrow ? "" : "hidden"
                }`}
            ></div>

            <div
              className={`absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 ${props.isLoadingEyeBrow ? "" : "hidden"
                }`}
            >
              <CircularProgress sx={{ color: "#ffffff" }} />
            </div>
          </div>
        </DetailModal>}
        <div className={`${props.header === "Before" ? "w-fit" : ""}`}>
          <p className="text-purple-500 text-sm">{props.header}</p>
          <div className="relative  cursor-pointer" ref={imageRef} onClick={() => setShowModalDetail(true)}>
            <div className="w-fit" id={props.element}>
              {frontImg && (
                <>
                  <img
                    src={frontImg}
                    alt="face-img"
                    className="max-w-full max-h-full"
                    onError={(e: any) => (e.target.src = NotAvailableImg)}
                    onLoad={handleImageLoad}
                  />

                  {/*--- Canvas eye brow ---*/}
                  <canvas
                    ref={canvasEyeBrowContour}
                    className={`absolute top-0 left-0 right-0`}
                  />

                  <canvas
                    ref={canvasEyeBrowAngle}
                    className={`absolute top-0 left-0 right-0`}
                  />

                  <canvas
                    ref={canvasEyeBrowFirstCorner}
                    className={`absolute top-0 left-0 right-0`}
                  />

                  <canvas
                    ref={canvasEyeBrowQuarter}
                    className={`absolute top-0 left-0 right-0`}
                  />

                  <canvas
                    ref={canvasEyeBrowSecondCorner}
                    className={`absolute top-0 left-0 right-0`}
                  />

                  {/* <canvas ref={canvasGoldenRatio} className={`absolute top-0 left-0 right-0`} /> */}
                </>
              )}
            </div>

            <div
              className={`after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-black after:opacity-90 ${props.isLoadingEyeBrow ? "" : "hidden"
                }`}
            ></div>

            <div
              className={`absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 ${props.isLoadingEyeBrow ? "" : "hidden"
                }`}
            >
              <CircularProgress sx={{ color: "#ffffff" }} />
            </div>
          </div>
        </div>
        {props.afterComponent}
      </div>

      {props.header === "Before" && (
        <div className="mt-4">
          {/*--- Eye brow angle ---*/}
          <ScanLevel
            img={EyeBrowIcon2}
            title="眉角度 (0°)"
            point={scanLevel}
            pointNames={["下がり眉", "平行", "上がり眉"]}
            otherPoint={
              <div className="flex justify-center gap-6 text-sm">
                <div>L: {eyeBrowLeftAngle}°</div>
                <div>R: {eyeBrowRightAngle}°</div>
              </div>
            }
            onSetOpenHelp={setIsOpenHelp}
          />

          {/*--- Eye brow first corner ---*/}
          <ScanLevel
            showBefore={false}
            img={EyeBrowIcon3}
            title="眉頭位置"
            isShowHelp={false}
          />

          {/*--- Eye brow quarter ---*/}
          <ScanLevel
            showBefore={false}
            img={EyeBrowIcon4}
            title="眉山位置"
            isShowHelp={false}
          />

          {/*--- Eye brow second corner ---*/}
          <ScanLevel
            showBefore={false}
            img={EyeBrowIcon5}
            title="眉尻位置"
            isShowHelp={false}
          />

          {/*--- Eye brow contour ---*/}
          <ScanLevel
            showBefore={false}
            img={EyeBrowIcon1}
            title="眉輪郭"
            isShowHelp={false}
          />
        </div>
      )}

      {isOpenHelp && (
        <HelpModal
          isOpen={isOpenHelp}
          closeModal={() => setIsOpenHelp(false)}
          title="眉"
          content={
            <div>
              <p className="font-bold">【黄金比】</p>
              ①小鼻の真っ直ぐ上に眉頭
              <br />
              ②目尻の真っ直ぐ上に眉やま
              <br />
              ③鼻と目尻の延長線上に眉尻
              <br />
              <br />
              <p className="font-bold">【ケース別アドバイス】</p>
              ①黄金比とズレがある場合
              <br />
              黄金比を基準に、お客さまの好みに応じて調整することより印象をアップできます。
              <br />
              <br />
              ②左右差がある場合
              <br />
              角度・長さなどの左右差を軽減するようにカット・メイク等で調整することがおすすめです。
              <div className="mt-10 flex justify-center">
                <img src={EyeBrowClinicImg} alt="eyebrow" />
              </div>
            </div>
          }
        />
      )}
    </>
  );
};

export default EyeBrowClinic;
