import { useState } from 'react'
import NoImage from "../NoImage";
import FaceContour from "./FaceContour";
import FaceRatio from "./FaceRatio";
import FaceTriangle from "./FaceTriangle";
import FacialLaterality from "./FacialLaterality";
import HorizontalBalance from "./HorizontalBalance";
import VerticalBalance from "./VerticalBalance";
import DetailModal from '../DetailModal'

const FACE_COMPONENTS = [
  HorizontalBalance,
  VerticalBalance,
  FaceRatio,
  FaceTriangle,
  FaceContour,
  FacialLaterality
]

interface IAllFaceScanProps {
  faceAnalysisData: any;
  frontImgUrl: any;
  afterFrontImgUrl: any;
  frontRealImgUrl?: any;
  afterRealImgUrl?: any;
}

const AllFaceScan: React.FC<IAllFaceScanProps> = ({
  faceAnalysisData,
  frontImgUrl,
  afterFrontImgUrl,
  frontRealImgUrl,
  afterRealImgUrl
}) => {
  const [showModalDetail, setShowModalDetail] = useState<boolean>(false);
  const [faceIndex, setFaceIndex] = useState<number>(0);
  const [isAfter, setIsAfter] = useState<boolean>(false);

  const onClick = (isAfter: boolean, index: number) => {
    setIsAfter(isAfter)
    setFaceIndex(index)
    setShowModalDetail(true)
  }

  const FaceComponentRenderer = () => {
    const Component = FACE_COMPONENTS[faceIndex]
    return Component ? <Component
      faceScanResult={isAfter ? faceAnalysisData?.after_front_image : faceAnalysisData?.front_image}
      frontImage={isAfter ? afterFrontImgUrl : frontImgUrl}
      frontRealImage={isAfter ? afterRealImgUrl : frontRealImgUrl}
      header=""
      imgFront={false}
    /> : null;
  };

  return (
    <div className="rounded-2xl bg-white p-2 w-fit mx-auto">
      <div className="text-2xl font-bold mb-2">顔全体のバランス</div>

      {faceAnalysisData && frontImgUrl && (
        <>
          {[
            { name: HorizontalBalance },
            { name: VerticalBalance },
            { name: FaceRatio },
            { name: FaceTriangle },
            { name: FaceContour },
            { name: FacialLaterality }
          ].map((config, index) => {
            const { name: AllFaceComponent } = config;

            return (
              <>
                <AllFaceComponent
                  key={index}
                  faceScanResult={faceAnalysisData?.front_image}
                  frontImage={frontImgUrl}
                  header={"Before"}
                  imgFront={true}
                  onClick={() => onClick(false, index)}
                  afterComponent={
                    faceAnalysisData && afterFrontImgUrl ? (
                      <AllFaceComponent
                        faceScanResult={faceAnalysisData?.after_front_image}
                        frontImage={afterFrontImgUrl}
                        header={"After"}
                        imgFront={false}
                        onClick={() => onClick(true, index)}
                      />
                    ) : (
                      <NoImage header={"After"} />
                    )
                  }
                />

              </>
            );
          })}
        </>
      )}
      {showModalDetail && <DetailModal isOpen={showModalDetail} closeModal={() => setShowModalDetail(false)} >
        {FaceComponentRenderer()}
      </DetailModal>}

    </div>
  );
};

export default AllFaceScan;
