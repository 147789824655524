import { CircularProgress } from "@mui/material";
// import { domToPng } from "modern-screenshot";
import { useRef, useState } from "react";

import FaceLower from "../../../../assets/icons/ic-lowerface.svg";
import NotAvailableImg from "../../../../assets/images/no-img-available.png";
import {
  // drawHorizontalBalanceLine,
  drawTrapezoidalArea // drawVerticalBalanceLine
} from "../../../../services/draw-face-analysis.service";
import {
  calculateFaceArea,
  calculateTrapezoidArea // getTrapezoidalArea
} from "../../../../utils/face-analysis";
import HelpModal from "../FaceScanData/HelpModal";
import ScanLevel from "../ScanLevel";
import DetailModal from './DetailModal'

interface ILowerHalfFaceClinicProps {
  dataFaceLandmark: any;
  dataFaceDenseLandmark: any;
  faceRectangle: any;
  frontImage: string;
  header: string;
  element: string;
  setIsLoadingLowerHalf: any;
  isLoadingLowerHalf: boolean;
  afterComponent?: React.ReactNode;
  frontRealImgUrl?: any
}

const LowerHalfFaceClinic = (props: ILowerHalfFaceClinicProps) => {
  // const isShowSection =
  //   props?.faceScanResult?.faceAnalysisResult?.checkedLowerFace;

  const frontImg = props?.frontImage;
  const frontRealImgUrl = props?.frontRealImgUrl;

  const [isOpenHelp, setIsOpenHelp] = useState<boolean>(false);
  const [showModalDetail, setShowModalDetail] = useState<boolean>(false);

  //--- Canvas for face
  const canvasTrapezoidalArea = useRef<HTMLCanvasElement>(null);
  // const canvasVerticalBalance = useRef<HTMLCanvasElement>(null);
  // const canvasHorizontalBalance = useRef<HTMLCanvasElement>(null);

  //--- TrapezoidalArea
  const [scanLevel, setScanLevel] = useState<number>(0);

  //--- Get data trapezoidal area
  const getDataTrapezoidalArea = (
    imgRealWidth: number,
    imgRenderWidth: number
  ) => {
    const imageScale = imgRealWidth / imgRenderWidth;

    // const square: any = getTrapezoidalArea(
    //   props?.dataFaceLandmark.contour_left2,
    //   props?.dataFaceLandmark.contour_right2,
    //   props?.dataFaceLandmark.contour_left6,
    //   props?.dataFaceLandmark.contour_right6
    // );
    // setTrapezoidalArea(Number(square));

    const trapezoidArea = calculateTrapezoidArea(
      props?.dataFaceLandmark,
      imageScale
    );

    const facialArea = calculateFaceArea(props?.dataFaceLandmark, imageScale);

    setScanLevel(calculateScanLevel(trapezoidArea / facialArea));
  };

  //--- Calculate scan level
  const calculateScanLevel = (ratioResult: any) => {
    if (ratioResult < 0.35) return 1;
    if (ratioResult >= 0.35 && ratioResult < 0.45) return 2;
    if (ratioResult >= 0.45 && ratioResult < 0.55) return 3;
    if (ratioResult >= 0.55 && ratioResult < 0.65) return 4;
    return 5;
  };

  //--- Generate image
  // const [lowerHalfFaceImgCrop, setLowerHalfFaceImgCrop] = useState<any>();

  // const generateImage = async (
  //   imgRealWidth: number,
  //   imgRenderWidth: number
  // ) => {
  //   props.setIsLoadingLowerHalf(true);

  //   const imageScale = imgRealWidth / imgRenderWidth;

  //   setTimeout(async () => {
  //     var lowerHalfFaceNode = document.getElementById(props.element);

  //     if (lowerHalfFaceNode === null) {
  //       return;
  //     }

  //     await domToPng(lowerHalfFaceNode).then(async (dataUrl: any) => {
  //       if (dataUrl) {
  //         if (lowerHalfFaceNode === null) {
  //           return;
  //         }

  //         await domToPng(lowerHalfFaceNode).then(async (dataUrl1: any) => {
  //           if (dataUrl1) {
  //             cropImage(
  //               dataUrl1,
  //               0,
  //               Math.floor(props.faceRectangle?.top / imageScale),
  //               imgRenderWidth,
  //               Math.floor(props.faceRectangle?.height / imageScale)
  //             );
  //           }
  //         });
  //       }
  //     });

  //     props.setIsLoadingLowerHalf(false);
  //   }, 2000);
  // };

  // const cropImage = (
  //   imageUrl: any,
  //   cropX: any,
  //   cropY: any,
  //   cropWidth: any,
  //   cropHeight: any
  // ) => {
  //   const canvas = document.createElement("canvas");

  //   if (canvas && imageUrl) {
  //     const image = new Image();

  //     image.onload = () => {
  //       canvas.width = cropWidth;
  //       canvas.height = cropHeight;

  //       const ctxCropImage = canvas.getContext("2d");

  //       if (ctxCropImage)
  //         ctxCropImage.drawImage(
  //           image,
  //           cropX,
  //           cropY,
  //           cropWidth,
  //           cropHeight,
  //           0,
  //           0,
  //           cropWidth,
  //           cropHeight
  //         );

  //       const dataUrl = canvas.toDataURL();

  //       setLowerHalfFaceImgCrop(dataUrl);
  //     };

  //     image.setAttribute("crossorigin", "anonymous");
  //     image.src = imageUrl;
  //   }
  // };

  //--- Handle load image
  const getDimensionsRealImg = (url: string, cb: any) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  const handleImageLoad = (e: any) => {
    props.setIsLoadingLowerHalf(true);

    const { width, height } = e.target;

    getDimensionsRealImg(frontImg, (err: any, img: any) => {
      let imgRealWidth = Number(img?.width);

      if (
        imgRealWidth > 0 &&
        width > 0 &&
        height > 0 &&
        props?.dataFaceLandmark &&
        props?.dataFaceDenseLandmark &&
        props?.faceRectangle &&
        frontImg
      ) {
        Promise.all([
          //--- Draw horizontal balance line
          // drawHorizontalBalanceLine(
          //   props.dataFaceLandmark,
          //   props.dataFaceDenseLandmark,
          //   canvasHorizontalBalance,
          //   imgRealWidth,
          //   width,
          //   height
          // ),
          //--- Draw vertical balance line
          // drawVerticalBalanceLine(
          //   props.dataFaceLandmark,
          //   props.dataFaceDenseLandmark,
          //   canvasVerticalBalance,
          //   imgRealWidth,
          //   width,
          //   height
          // ),
          //--- Draw trapezoidal area
          drawTrapezoidalArea(
            props?.dataFaceLandmark,
            canvasTrapezoidalArea,
            imgRealWidth,
            width,
            height
          ),
          //--- Get data trapezoidal area
          getDataTrapezoidalArea(imgRealWidth, width),

          props.setIsLoadingLowerHalf(false)
          //--- Generate image
          // generateImage(imgRealWidth, width)
        ]);
      }
    });
  };

  return (
    <>
      <div
        className={`${props.header === "Before" ? "grid grid-cols-2 gap-4" : "w-full"}`}
      >
        <div className={`${props.header === "Before" ? "w-fit" : ""}`}>
          <p className="text-purple-500 text-sm">{props.header}</p>
          <DetailModal isOpen={showModalDetail} closeModal={() => setShowModalDetail(false)} >
            <div className="relative">
              <div className="w-fit" id={props.element}>
                {frontImg && (
                  <>
                    <img
                      src={frontRealImgUrl || frontImg}
                      alt="face-img"
                      className="max-w-full max-h-full"
                      onError={(e: any) => (e.target.src = NotAvailableImg)}
                      onLoad={handleImageLoad}
                    />

                    <canvas
                      ref={canvasTrapezoidalArea}
                      className={`absolute top-0 left-0 right-0`}
                    ></canvas>
                  </>
                )}
              </div>

              <div
                className={`after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-black after:opacity-90 ${props.isLoadingLowerHalf ? "" : "hidden"
                  }`}
              ></div>

              <div
                className={`absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 ${props.isLoadingLowerHalf ? "" : "hidden"
                  }`}
              >
                <CircularProgress sx={{ color: "#ffffff" }} />
              </div>
              {/* </>
            )} */}
            </div></DetailModal>
          <div className="relative cursor-pointer" onClick={() => setShowModalDetail(true)}>
            <div className="w-fit" id={props.element}>
              {frontImg && (
                <>
                  <img
                    src={frontImg}
                    alt="face-img"
                    className="max-w-full max-h-full"
                    onError={(e: any) => (e.target.src = NotAvailableImg)}
                    onLoad={handleImageLoad}
                  />

                  <canvas
                    ref={canvasTrapezoidalArea}
                    className={`absolute top-0 left-0 right-0`}
                  ></canvas>

                  {/* <canvas
                    ref={canvasHorizontalBalance}
                    className={`absolute top-0 left-0 right-0`}
                  ></canvas> */}

                  {/* <canvas
                    ref={canvasVerticalBalance}
                    className={`absolute top-0 left-0 right-0`}
                  ></canvas> */}
                </>
              )}
            </div>

            <div
              className={`after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-black after:opacity-90 ${props.isLoadingLowerHalf ? "" : "hidden"
                }`}
            ></div>

            <div
              className={`absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 ${props.isLoadingLowerHalf ? "" : "hidden"
                }`}
            >
              <CircularProgress sx={{ color: "#ffffff" }} />
            </div>
            {/* </>
            )} */}
          </div>
        </div>

        {props.afterComponent}
      </div>

      {props.header === "Before" && (
        <div className="mt-4">
          <ScanLevel
            img={FaceLower}
            title="台形面積"
            point={scanLevel}
            pointNames={["狭い", "平均顔", "広い"]}
            onSetOpenHelp={setIsOpenHelp}
          />
        </div>
      )}

      {isOpenHelp && (
        <HelpModal
          isOpen={isOpenHelp}
          closeModal={() => setIsOpenHelp(false)}
          title="台形面積"
          content={
            <div>
              顔全体の面積に占める下半分の比率がわかります。
              <br />
              <br />
              <p className="font-bold">【アドバイス】</p>
              ①台形面積が広い場合
              <br />
              ・顔がたるんでいる可能性があります。加齢共に下がりやすいエリアのため、顔のエラ、顎のラインを引き締めるような施術を提案できます
              <br />
              <br />
              ・広さをカバーするような髪型やメイクを提案できます
            </div>
          }
        />
      )}
    </>
  );
};

export default LowerHalfFaceClinic;
