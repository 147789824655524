import { CircularProgress } from "@mui/material";
// import { domToPng } from "modern-screenshot";
import { useRef, useState } from "react";
import html2canvas from "html2canvas";
import Loading from "components/Loading";
import MouthIcon1 from "../../../../assets/icons/ic-mouth01.svg";
import MouthIcon2 from "../../../../assets/icons/ic-mouth02.svg";
import MouthIcon3 from "../../../../assets/icons/ic-mouth03.svg";
import MouthIcon4 from "../../../../assets/icons/ic-mouth04.svg";
import NotAvailableImg from "../../../../assets/images/no-img-available.png";
import RatioSlider from "../../../../components/RatioSlider";
import {
  drawMouthAngle,
  drawMouthContour,
  drawMouthToChin,
  drawMouthToNose
} from "../../../../services/draw-face-analysis.service";
import {
  calculateTwoRatio,
  getDistanceOfTwoPoint,
  getLeftTriangleAngle,
  getRightTriangleAngle
} from "../../../../utils/face-analysis";
import HelpModal from "../FaceScanData/HelpModal";
import ScanLevel from "../ScanLevel";
import DetailModal from './DetailModal'

interface IMouthClinicProps {
  dataFaceLandmark: any;
  dataFaceDenseLandmark: any;
  faceRectangle: any;
  frontImage: string;
  header: string;
  element: string;
  setIsLoadingMouth: any;
  isLoadingMouth: boolean;
  afterComponent?: React.ReactNode;
  frontRealImgUrl?: any
}

const MouthClinic = (props: IMouthClinicProps) => {
  // const isShowSection =
  //   props?.faceScanResult?.faceAnalysisResult?.checkedMouthFeature;

  const frontImg = props?.frontImage;
  const frontRealImgUrl = props?.frontRealImgUrl;

  const [isOpenHelp, setIsOpenHelp] = useState<boolean>(false);
  const [isOpenHelp1, setIsOpenHelp1] = useState<boolean>(false);
  const [isOpenHelp2, setIsOpenHelp2] = useState<boolean>(false);
  const [showModalDetail, setShowModalDetail] = useState<boolean>(false);
  const [modalImageUrl, setModalImageUrl] = useState<any>();
  const imageRef = useRef(null);

  //--- Canvas for mouth
  const canvasMouthContour = useRef<HTMLCanvasElement>(null);
  const canvasMouthAngle = useRef<HTMLCanvasElement>(null);
  const canvasMouthToNose = useRef<HTMLCanvasElement>(null);
  const canvasMouthToChin = useRef<HTMLCanvasElement>(null);

  //--- Get data mouth angle
  const [mouthLeftAngle, setMouthLeftAngle] = useState<number>(0);
  const [mouthRightAngle, setMouthRightAngle] = useState<number>(0);
  const [scanLevelMouthAngle, setScanLevelMouthAngle] = useState<number>(0);

  const getDataMouthAngle = () => {
    if (props.dataFaceLandmark) {
      const angleLeft = getLeftTriangleAngle(
        props.dataFaceLandmark.mouth_lower_lip_left_contour3,
        props.dataFaceLandmark.mouth_left_corner
      );

      const angleRight = getRightTriangleAngle(
        props.dataFaceLandmark.mouth_lower_lip_right_contour3,
        props.dataFaceLandmark.mouth_right_corner
      );

      setMouthLeftAngle(Number(angleLeft.toFixed(0)));
      setMouthRightAngle(Number(angleRight.toFixed(0)));

      setScanLevelMouthAngle(
        calculateScanLevelMouthAngle(Number(angleRight.toFixed(0)))
      );
    }
  };

  //--- Calculate scan level
  const calculateScanLevelMouthAngle = (ratioResult: any) => {
    if (ratioResult < 35) return 1;
    if (ratioResult >= 35 && ratioResult < 40) return 2;
    if (ratioResult >= 40 && ratioResult < 45) return 3;
    if (ratioResult >= 45 && ratioResult < 50) return 4;
    return 5;
  };

  //--- Mouth to nose ratio
  const [mouthToNoseRatio1, setMouthToNoseRatio1] = useState<number>(0);
  const [mouthToNoseRatio2, setMouthToNoseRatio2] = useState<number>(0);
  const [scanLevelMouthToNoseRatio, setScanLevelMouthToNoseRatio] =
    useState<number>(0);

  const marksMouthToNoseRatio = [
    {
      value: 0
    },
    {
      value: mouthToNoseRatio1 > 0 && mouthToNoseRatio1
    },
    {
      value: 100
    }
  ];

  //--- Get data mouth to nose ratio
  const getDataMouthToNoseRatio = () => {
    if (props.dataFaceLandmark) {
      const distinct1 = getDistanceOfTwoPoint(
        props.dataFaceLandmark.nose_contour_lower_middle,
        props.dataFaceLandmark.mouth_upper_lip_top
      );

      const distinct2 = getDistanceOfTwoPoint(
        props.dataFaceLandmark.mouth_lower_lip_bottom,
        props.dataFaceLandmark.contour_chin
      );

      const { ratio1, ratio2 } = calculateTwoRatio(distinct1, distinct2);

      setMouthToNoseRatio1(Number(ratio1));
      setMouthToNoseRatio2(Number(ratio2));

      const ratioResult = Number(Number(ratio1) / Number(ratio2));
      setScanLevelMouthToNoseRatio(calculateScanLevelNoseRatio(ratioResult));
    }
  };

  //--- Calculate scan level
  const calculateScanLevelNoseRatio = (ratioResult: any) => {
    if (ratioResult < 0.55) return 1;
    if (ratioResult >= 0.55 && ratioResult < 0.6) return 2;
    if (ratioResult >= 0.6 && ratioResult < 0.7) return 3;
    if (ratioResult >= 0.7 && ratioResult < 0.8) return 4;
    return 5;
  };

  //--- Mouth to chin ratio
  const [mouthToChinRatio1, setMouthToChinRatio1] = useState<number>(0);
  const [mouthToChinRatio2, setMouthToChinRatio2] = useState<number>(0);
  const [scanLevelMouthToChinRatio, setScanLevelMouthToChinRatio] =
    useState<number>(0);

  const marksMouthToChinRatio = [
    {
      value: 0
    },
    {
      value: mouthToChinRatio1 > 0 && mouthToChinRatio1
    },
    {
      value: 100
    }
  ];

  //--- Get data mouth to chin ratio
  const getDataMouthToChinRatio = () => {
    if (props.dataFaceLandmark) {
      const distinct1 = getDistanceOfTwoPoint(
        props.dataFaceLandmark.nose_contour_lower_middle,
        props.dataFaceLandmark.mouth_lower_lip_bottom
      );

      const distinct2 = getDistanceOfTwoPoint(
        props.dataFaceLandmark.mouth_lower_lip_bottom,
        props.dataFaceLandmark.contour_chin
      );

      const { ratio1, ratio2 } = calculateTwoRatio(distinct1, distinct2);

      setMouthToChinRatio1(Number(ratio1));
      setMouthToChinRatio2(Number(ratio2));

      const ratioResult = Number(Number(ratio1) / Number(ratio2));
      setScanLevelMouthToChinRatio(
        calculateScanLevelMouthToChinRatio(ratioResult)
      );
    }
  };

  //--- Calculate scan level
  const calculateScanLevelMouthToChinRatio = (ratioResult: any) => {
    if (ratioResult > 1.2) return 1;
    else if (ratioResult >= 1.1) return 2;
    else if (ratioResult >= 0.9) return 3;
    else if (ratioResult >= 0.8) return 4;
    return 5;
  };

  //--- Generate image
  // const [mouthImgCrop, setMouthImgCrop] = useState<any>();

  // const generateImage = async (
  //   imgRealWidth: number,
  //   imgRenderWidth: number
  // ) => {
  //   props.setIsLoadingMouth(true);

  //   const imageScale = imgRealWidth / imgRenderWidth;

  //   setTimeout(async () => {
  //     var mouthNode = document.getElementById(props.element);

  //     if (mouthNode === null) {
  //       return;
  //     }

  //     await domToPng(mouthNode).then((dataUrl: any) => {
  //       if (dataUrl) {
  //         cropImage(
  //           dataUrl,
  //           0,
  //           Math.floor(props.faceRectangle?.top / imageScale),
  //           imgRenderWidth,
  //           Math.floor(props.faceRectangle?.height / imageScale) + 10
  //           // Math.floor(props.dataFaceLandmark?.contour_left4?.x / imageScale),
  //           // Math.floor(props.dataFaceLandmark?.contour_left4?.y / imageScale),
  //           // Math.floor(props.dataFaceLandmark?.contour_right4?.x / imageScale) -
  //           //   Math.floor(props.dataFaceLandmark?.contour_left4?.x / imageScale),
  //           // Math.floor(props.dataFaceLandmark?.contour_right8?.y / imageScale) -
  //           //   Math.floor(props.dataFaceLandmark?.contour_right4?.y / imageScale)
  //         );
  //       }
  //     });
  //   }, 10000);

  //   props.setIsLoadingMouth(false);
  // };

  // const cropImage = (
  //   imageUrl: any,
  //   cropX: any,
  //   cropY: any,
  //   cropWidth: any,
  //   cropHeight: any
  // ) => {
  //   const canvas = document.createElement("canvas");

  //   if (canvas && imageUrl) {
  //     const image = new Image();

  //     image.onload = () => {
  //       canvas.width = cropWidth;
  //       canvas.height = cropHeight;

  //       const ctxCropImage = canvas.getContext("2d");

  //       if (ctxCropImage)
  //         ctxCropImage.drawImage(
  //           image,
  //           cropX,
  //           cropY,
  //           cropWidth,
  //           cropHeight,
  //           0,
  //           0,
  //           cropWidth,
  //           cropHeight
  //         );

  //       const dataUrl = canvas.toDataURL();

  //       setMouthImgCrop(dataUrl);
  //     };

  //     image.setAttribute("crossorigin", "anonymous");
  //     image.src = imageUrl;
  //   }
  // };

  //--- Handle load image
  const getDimensionsRealImg = (url: string, cb: any) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  const handleImageLoad = (e: any) => {
    props.setIsLoadingMouth(true);

    const { width, height } = e.target;

    getDimensionsRealImg(frontImg, (err: any, img: any) => {
      let imgRealWidth = Number(img?.width);

      if (
        imgRealWidth > 0 &&
        width > 0 &&
        height > 0 &&
        props?.dataFaceLandmark &&
        props?.dataFaceDenseLandmark &&
        frontImg
      ) {
        Promise.all([
          //--- Draw mouth contour
          drawMouthContour(
            props?.dataFaceLandmark,
            props?.dataFaceDenseLandmark,
            canvasMouthContour,
            imgRealWidth,
            width,
            height
          ),
          //--- Draw mouth angle
          drawMouthAngle(
            props?.dataFaceLandmark,
            canvasMouthAngle,
            imgRealWidth,
            width,
            height
          ),
          //--- Draw mouth to nose
          drawMouthToNose(
            props?.dataFaceLandmark,
            canvasMouthToNose,
            imgRealWidth,
            width,
            height
          ),
          //--- Draw mouth to chin
          drawMouthToChin(
            props?.dataFaceLandmark,
            canvasMouthToChin,
            imgRealWidth,
            width,
            height
          ),
          //--- Get data mouth angle
          getDataMouthAngle(),
          //--- Get data mouth to nose ratio
          getDataMouthToNoseRatio(),
          //--- Get data mouth to chin ratio
          getDataMouthToChinRatio(),

          props.setIsLoadingMouth(false)
          //--- Generate image
          // generateImage(imgRealWidth, width)
        ]);
      }
    });
  };

  return (
    <>
      <div
        className={`${props.header === "Before" ? "grid grid-cols-2 gap-4" : "w-full"}`}
      >
        {frontImg && <DetailModal isOpen={showModalDetail} closeModal={() => setShowModalDetail(false)} >
          <div className="relative">
            <div className="w-fit" id={props.element}>
              {frontImg && (
                <>
                  <img
                    src={frontRealImgUrl || frontImg}
                    alt="face-img"
                    className="max-w-full max-h-full"
                    onError={(e: any) => (e.target.src = NotAvailableImg)}
                    onLoad={handleImageLoad}
                  />

                  {/*--- Canvas mouth ---*/}
                  <canvas
                    ref={canvasMouthContour}
                    className={`absolute top-0 left-0 right-0`}
                  />

                  <canvas
                    ref={canvasMouthAngle}
                    className={`absolute top-0 left-0 right-0`}
                  />

                  <canvas
                    ref={canvasMouthToNose}
                    className={`absolute top-0 left-0 right-0`}
                  />

                  <canvas
                    ref={canvasMouthToChin}
                    className={`absolute top-0 left-0 right-0`}
                  />
                </>
              )}
            </div>

            <div
              className={`after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-black after:opacity-90 ${props.isLoadingMouth ? "" : "hidden"
                }`}
            ></div>

            <div
              className={`absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 ${props.isLoadingMouth ? "" : "hidden"
                }`}
            >
              <CircularProgress sx={{ color: "#ffffff" }} />
            </div>
          </div>

        </DetailModal>}

        <div className={`${props.header === "Before" ? "w-fit" : ""}`}>
          <p className="text-purple-500 text-sm">{props.header}</p>
          <div className="relative cursor-pointer" onClick={() => setShowModalDetail(true)}>
            <div className="w-fit" id={props.element}>
              {frontImg && (
                <>
                  <img
                    src={frontImg}
                    alt="face-img"
                    className="max-w-full max-h-full"
                    onError={(e: any) => (e.target.src = NotAvailableImg)}
                    onLoad={handleImageLoad}
                  />

                  {/*--- Canvas mouth ---*/}
                  <canvas
                    ref={canvasMouthContour}
                    className={`absolute top-0 left-0 right-0`}
                  />

                  <canvas
                    ref={canvasMouthAngle}
                    className={`absolute top-0 left-0 right-0`}
                  />

                  <canvas
                    ref={canvasMouthToNose}
                    className={`absolute top-0 left-0 right-0`}
                  />

                  <canvas
                    ref={canvasMouthToChin}
                    className={`absolute top-0 left-0 right-0`}
                  />
                </>
              )}
            </div>

            <div
              className={`after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-black after:opacity-90 ${props.isLoadingMouth ? "" : "hidden"
                }`}
            ></div>

            <div
              className={`absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 ${props.isLoadingMouth ? "" : "hidden"
                }`}
            >
              <CircularProgress sx={{ color: "#ffffff" }} />
            </div>
            {/* </>
          )} */}

            {/* <div className="mt-12 w-full h-[1px] bg-[#262626]" />
            <div className="p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <img src={MouthIcon1} alt="ratio icon" />
                  <span className="ml-2">口輪郭</span>
                </div>
              </div>
            </div>
            <div className="mt-2 w-full h-[1px] bg-[#262626]" /> */}

            {/*--- Mouth angle ---*/}
            {/* <div className="p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <img src={MouthIcon2} alt="ratio icon" />
                  <span className="ml-2">口角角度</span>
                  <span className="ml-2">
                    {mouthLeftAngle > 0 &&
                      mouthRightAngle > 0 &&
                      `(${mouthRightAngle}° , ${mouthLeftAngle}°)`}
                  </span>
                </div>
              </div>
              <div className="text-sm flex gap-x-11">
                <span>{mouthRightAngle > 0 && `R: ${mouthRightAngle}°`}</span>
                <span className="ml-10">
                  {mouthLeftAngle > 0 && `L: ${mouthLeftAngle}°`}
                </span>
              </div>
            </div>
            <div className="mt-2 w-full h-[1px] bg-[#262626]" /> */}

            {/*--- Mouth to nose ---*/}
            {/* <div className="p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <img src={MouthIcon3} alt="ratio icon" />
                  <span className="ml-2">鼻下・顎の長さ比</span>
                  <span className="ml-2">(1:1)</span>
                </div>
              </div>

              {mouthToNoseRatio1 > 0 && mouthToNoseRatio2 > 0 && (
                <div>
                  <div className="clinic-proposal-custom-ratio">
                    <RatioSlider marksArr={marksMouthToNoseRatio} />
                  </div>
                  <div className="flex relative text-xs text-black">
                    <span
                      className="absolute"
                      style={{
                        top: "-10px",
                        left: `calc((${Math.ceil(
                          mouthToNoseRatio1
                        )}% / 2) - 1rem)`
                      }}
                    >
                      {mouthToNoseRatio1}
                    </span>
                    <span
                      className="absolute"
                      style={{
                        top: "-10px",
                        left: `calc((${Math.ceil(
                          mouthToNoseRatio1
                        )}% + (${Math.ceil(mouthToNoseRatio2)}% / 2)) - 1rem)`
                      }}
                    >
                      {mouthToNoseRatio2}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-2 w-full h-[1px] bg-[#262626]" /> */}

            {/*--- Mouth to chin ---*/}
            {/* <div className="p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <img src={MouthIcon4} alt="ratio icon" />
                  <span className="ml-2">鼻下~唇下・唇下~顎の長さ比</span>
                  <span className="ml-2">(1:1)</span>
                </div>
              </div>

              {mouthToChinRatio1 > 0 && mouthToChinRatio2 > 0 && (
                <div>
                  <div className="clinic-proposal-custom-ratio">
                    <RatioSlider marksArr={marksMouthToChinRatio} />
                  </div>
                  <div className="flex relative text-xs text-black">
                    <span
                      className="absolute"
                      style={{
                        top: "-10px",
                        left: `calc((${Math.ceil(
                          mouthToChinRatio1
                        )}% / 2) - 1rem)`
                      }}
                    >
                      {mouthToChinRatio1}
                    </span>
                    <span
                      className="absolute"
                      style={{
                        top: "-10px",
                        left: `calc((${Math.ceil(
                          mouthToChinRatio1
                        )}% + (${Math.ceil(mouthToChinRatio2)}% / 2)) - 1rem)`
                      }}
                    >
                      {mouthToChinRatio2}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-2 w-full h-[1px] bg-[#262626]" /> */}
          </div>
        </div>

        {props.afterComponent}
      </div>

      {props.header === "Before" && (
        <div className="mt-4">
          {/*--- Mouth angle ---*/}
          <ScanLevel
            img={MouthIcon2}
            title="口角角度 (45°)"
            point={scanLevelMouthAngle}
            pointNames={["下がり気味", "平均", "上がり気味"]}
            otherPoint={
              <div className="flex justify-center gap-6 text-sm">
                <div>
                  L: {mouthLeftAngle}° (
                  <span className="text-[#33B294]">
                    {mouthLeftAngle - 45 > 0 ? "+" : "-"}
                    {Math.abs(mouthLeftAngle - 45)}
                  </span>
                  )
                </div>
                <div>
                  R: {mouthRightAngle}° (
                  <span className="text-[#33B294]">
                    {mouthRightAngle - 45 > 0 ? "+" : "-"}
                    {Math.abs(mouthRightAngle - 45)}
                  </span>
                  )
                </div>
              </div>
            }
            onSetOpenHelp={setIsOpenHelp}
          />

          {/*--- Mouth to nose ---*/}
          <ScanLevel
            img={MouthIcon3}
            title="人中・あごの長さ比 (2:3)"
            point={scanLevelMouthToNoseRatio}
            pointNames={["人中短め", "平均", "人中長め"]}
            measure={
              mouthToNoseRatio1 > 0 &&
              mouthToNoseRatio2 > 0 && (
                <div>
                  <div className="clinic-proposal-custom-ratio">
                    <RatioSlider marksArr={marksMouthToNoseRatio} />
                  </div>
                  <div className="flex relative text-xs text-black">
                    <span
                      className="absolute"
                      style={{
                        top: "-10px",
                        left: `calc((${Math.ceil(
                          mouthToNoseRatio1
                        )}% / 2) - 1rem)`
                      }}
                    >
                      人中: {mouthToNoseRatio1}
                    </span>
                    <span
                      className="absolute"
                      style={{
                        top: "-10px",
                        left: `calc((${Math.ceil(
                          mouthToNoseRatio1
                        )}% + (${Math.ceil(mouthToNoseRatio2)}% / 2)) - 1rem)`
                      }}
                    >
                      あご: {mouthToNoseRatio2}
                    </span>
                  </div>
                </div>
              )
            }
            onSetOpenHelp={setIsOpenHelp1}
          />

          {/*--- Mouth to chin ---*/}
          <ScanLevel
            img={MouthIcon4}
            title="鼻下~唇下・あごの長さ比 (1:1)"
            point={scanLevelMouthToChinRatio}
            pointNames={["あご短め", "平均", "あご長め"]}
            measure={
              mouthToChinRatio1 > 0 &&
              mouthToChinRatio2 > 0 && (
                <div>
                  <div className="clinic-proposal-custom-ratio">
                    <RatioSlider marksArr={marksMouthToChinRatio} />
                  </div>
                  <div className="flex relative text-xs text-black">
                    <span
                      className="absolute"
                      style={{
                        top: "-10px",
                        left: `calc((${Math.ceil(
                          mouthToChinRatio1
                        )}% / 2) - 1rem)`
                      }}
                    >
                      鼻下~唇下: {mouthToChinRatio1}
                    </span>
                    <span
                      className="absolute"
                      style={{
                        top: "-10px",
                        left: `calc((${Math.ceil(
                          mouthToChinRatio1
                        )}% + (${Math.ceil(mouthToChinRatio2)}% / 2)) - 1rem)`
                      }}
                    >
                      あご: {mouthToChinRatio2}
                    </span>
                  </div>
                </div>
              )
            }
            onSetOpenHelp={setIsOpenHelp2}
          />

          <ScanLevel
            img={MouthIcon1}
            title="口輪郭"
            showBefore={false}
            onSetOpenHelp={setIsOpenHelp}
          />
        </div>
      )}

      {isOpenHelp && (
        <HelpModal
          isOpen={isOpenHelp}
          closeModal={() => setIsOpenHelp(false)}
          title="口角角度・口輪郭"
          content={
            <div>
              <p className="font-bold">【アドバイス】</p>{" "}
              ①口角角度が下がり気味の場合
              <br />
              口角角度を上げるメイク・施術がオススメです。
              <br />
              <br />
              ②口輪郭が薄い場合
              <br />
              ぷっくりとした印象を与えるメイク・施術を提案できます。
              <br />
              <br />
              ③口輪郭が厚い場合
              <br />
              厚みを軽減または引き立てるメイク・施術を提案できます。
            </div>
          }
        />
      )}

      {isOpenHelp1 && (
        <HelpModal
          isOpen={isOpenHelp1}
          closeModal={() => setIsOpenHelp1(false)}
          title="人中・あごの長さ比"
          content={
            <div>
              <p className="font-bold">【黄金比】</p>
              人中:あごの長さ = 1:1.5
              <br />
              <br />
              <p className="font-bold">【アドバイス】</p>
              ①人中長めの場合
              <br />
              人中短縮や、口をぷっくりさせるなどの施術を提案できます
            </div>
          }
        />
      )}

      {isOpenHelp2 && (
        <HelpModal
          isOpen={isOpenHelp2}
          closeModal={() => setIsOpenHelp2(false)}
          title="鼻下~唇下・あごの長さ比"
          content={
            <div>
              <p className="font-bold">【黄金比】</p>
              鼻下~唇下:あごの長さ = 1:1
              <br />
              <br />
              <p className="font-bold">【アドバイス】</p>
              ①あご長めの場合
              <br />
              おとがい（顎先骨）骨切り術、脂肪吸引などあごの長さを調整する施術をオススメできます
            </div>
          }
        />
      )}
    </>
  );
};

export default MouthClinic;
