import { Modal } from "@mui/material";
import React from "react";
import CloseIcon from "assets/icons/close-white-icon.svg";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  children: React.ReactNode
}
const DetailModal: React.FC<ModalProps> = ({
  closeModal,
  isOpen,
  children
}: ModalProps) => {
  return (
    <Modal open={isOpen} onClose={closeModal}>
      <div className="fixed inset-0 flex items-center justify-center md:w-[416px] mx-auto">
        <img src={CloseIcon} alt="close" className="absolute right-5 top-5 cursor-pointer" onClick={closeModal} />
        {children}
      </div>
    </Modal>
  );
};

export default DetailModal;
