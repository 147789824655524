import { CircularProgress } from "@mui/material";
import FaceIcon5 from "assets/icons/ic-face05.svg";
import NotAvailableImg from "assets/images/no-img-available.png";
import { useEffect, useRef, useState } from "react";
import { drawTriangleLine } from "services/draw-face-analysis.service";
import { calculateTrianglePoint, getTrianglePoint } from "utils/face-analysis";

import HelpModal from "../../FaceScanData/HelpModal";
import ScanLevel from "../../ScanLevel";

interface IFaceClinicProps {
  faceScanResult: any;
  frontImage: string;
  header?: string;
  imgFront: boolean;
  afterComponent?: React.ReactNode;
  onClick?: any;
  frontRealImage?: any;
}

const FaceTriangle = (props: IFaceClinicProps) => {
  const [isLoadingFace, setIsLoadingFace] = useState<boolean>(true);
  const [isOpenHelp, setIsOpenHelp] = useState<boolean>(false);

  //--- Canvas for face
  const canvasTriangle = useRef<HTMLCanvasElement>(null);

  //--- Get data landmark
  const [dataFaceLandmark, setDataFaceLandmark] = useState<any>();

  const getDataLandMark = async () => {
    const landmark =
      props?.faceScanResult?.faceLandmarkResult?.dataFrontFaceLandmark;

    setDataFaceLandmark(landmark);

    setTimeout(() => {
      getDataDenseLandmark();
    }, 2000);
  };

  //--- Get data dense landmark
  const [dataFaceDenseLandmark, setDataFaceDenseLandmark] = useState<any>();

  const getDataDenseLandmark = async () => {
    const faceLandmark = props?.faceScanResult?.faceDenseLandmarkResult;
    setDataFaceDenseLandmark(faceLandmark);
    setIsLoadingFace(false);
  };

  //--- Load data face
  const onLoadDataFace = async () => {
    if (
      props.faceScanResult?.faceLandmarkResult?.dataFrontFaceLandmark &&
      props.faceScanResult?.faceLandmarkResult?.dataFrontFaceRectangle &&
      props.faceScanResult?.faceDenseLandmarkResult
    ) {
      setDataFaceLandmark(
        props.faceScanResult?.faceLandmarkResult?.dataFrontFaceLandmark
      );

      setDataFaceDenseLandmark(props.faceScanResult?.faceDenseLandmarkResult);

      setIsLoadingFace(false);
    } else {
      Promise.all([getDataLandMark()]);
    }
  };

  //--- Triangle point
  const [trianglePoint, setTrianglePoint] = useState<number>(0);
  const [scanLevel, setScanLevel] = useState<number>(0);

  //--- Get data triangle point
  const getDataTrianglePoint = () => {
    const { firstPint, secondPint, thirdPint } =
      getTrianglePoint(dataFaceLandmark);

    const trianglePoint = calculateTrianglePoint(
      firstPint,
      secondPint,
      thirdPint
    );

    setTrianglePoint(Number(trianglePoint));

    setScanLevel(calculateScanLevel(Number(trianglePoint)));
  };

  //--- Calculate scan level
  const STANDARD_ANGLE = 60;

  const calculateScanLevel = (ratioResult: any) => {
    const n = Math.abs(ratioResult - STANDARD_ANGLE);

    if (n >= 15) {
      return ratioResult > 60 ? 1 : 5;
    } else if (n >= 5) {
      return ratioResult > 60 ? 2 : 4;
    } else {
      return 3;
    }
  };

  //--- Handle load image
  const [dimensionsRealImg, setDimensionsRealImg] = useState({
    width: 0,
    height: 0
  });
  const [dimensionsRenderImg, setDimensionsRenderImg] = useState({
    width: 0,
    height: 0
  });

  const getDimensionsRealImg = (url: string, cb: any) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  const handleImageLoad = (e: any) => {
    setTimeout(() => {
      const { width, height } = e.target;

      if (width > 0 && height > 0 && props.frontImage) {
        setDimensionsRenderImg({ width, height });

        getDimensionsRealImg(props.frontImage, (err: any, img: any) => {
          let imgRealWidth = Number(img?.width);
          let imageRealHeight = Number(img?.height);

          setDimensionsRealImg({
            width: imgRealWidth,
            height: imageRealHeight
          });

          if (props.frontImage && imgRealWidth > 0 && imageRealHeight > 0) {
            onLoadDataFace();
          }
        });
      }
    }, 2000);
  };

  useEffect(() => {
    if (
      dataFaceLandmark &&
      dataFaceDenseLandmark &&
      dimensionsRealImg.width > 0 &&
      dimensionsRenderImg.width > 0 &&
      dimensionsRenderImg.height > 0 &&
      props.frontImage
    ) {
      Promise.all([
        //--- Get data triangle point
        getDataTrianglePoint(),

        //--- Draw triangle line
        drawTriangleLine(
          dataFaceLandmark,
          canvasTriangle,
          dimensionsRealImg.width,
          dimensionsRenderImg.width,
          dimensionsRenderImg.height
        )
      ]);
    }
  }, [dataFaceLandmark, dataFaceDenseLandmark]);

  return (
    <>
      <div
        className={`${props.imgFront ? "grid grid-cols-2 gap-4" : "w-full"}`}
      >
        <div className={`${props.imgFront ? "w-fit" : ""}`}>
          <p className="text-purple-500 text-sm">{props.header}</p>
          <div className="relative">
            <div className="w-fit cursor-pointer mx-auto" onClick={props?.onClick}>
              <img
                src={props?.frontRealImage || props?.frontImage}
                alt="balance-face"
                className="max-w-full max-h-full"
                onError={(e: any) => (e.target.src = NotAvailableImg)}
                onLoad={handleImageLoad}
              />

              {/*--- Canvas face ---*/}
              <canvas
                ref={canvasTriangle}
                className={`absolute top-0 left-0 right-0 left-1/2 transform -translate-x-1/2`}
              ></canvas>
            </div>

            <div
              className={`after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-black after:opacity-90 ${isLoadingFace ? "" : "hidden"
                }`}
            ></div>

            <div
              className={`absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 ${isLoadingFace ? "" : "hidden"
                }`}
            >
              <CircularProgress sx={{ color: "#ffffff" }} />
            </div>
          </div>
        </div>

        {props.afterComponent}
      </div>

      {props.imgFront && (
        <div className="mt-4">
          {/*--- Triangle ---*/}
          <ScanLevel
            img={FaceIcon5}
            title={`逆三角形鼻位置の角度 (60°)`}
            point={scanLevel}
            pointNames={["子ども顔", "平均顔", "大人顔"]}
            otherPoint={
              <div className="text-sm text-center">
                {trianglePoint}° (
                <span className="text-[#33B294]">
                  {trianglePoint - STANDARD_ANGLE > 0 ? "+" : "-"}
                  {Number(trianglePoint - STANDARD_ANGLE).toFixed(1)}°
                </span>
                )
              </div>
            }
            onSetOpenHelp={setIsOpenHelp}
          />
        </div>
      )}

      {isOpenHelp && (
        <HelpModal
          isOpen={isOpenHelp}
          closeModal={() => setIsOpenHelp(false)}
          title="逆三角形鼻位置の角度"
          content={
            <div>
              鼻側の角度が60度より小さいと大人っぽく見え、60度より大きいと幼い印象を与えます。
              <br />
              <br />
              <p className="font-bold">【アドバイス】</p>{" "}
              ①大人顔（60度より大きい）場合
              <br />
              お客さまの希望に合わせ、大人っぽさを強調または和らげる髪型や施術がおすすめ
              <br />
              <br />
              ②子ども顔（60度より小さい）場合
              <br />
              お客さまの希望に合わせ、幼さを強調または和らげる髪型や施術がおすすめ
            </div>
          }
        />
      )}
    </>
  );
};

export default FaceTriangle;
