import { CircularProgress } from "@mui/material";
import FaceIcon3 from "assets/icons/ic-face03.svg";
import NotAvailableImg from "assets/images/no-img-available.png";
import { useEffect, useRef, useState } from "react";
import { drawFaceContour } from "services/draw-face-analysis.service";

import HelpModal from "../../FaceScanData/HelpModal";
import ScanLevel from "../../ScanLevel";

interface IFaceClinicProps {
  faceScanResult: any;
  frontImage: string;
  header?: string;
  imgFront: boolean;
  afterComponent?: React.ReactNode;
  onClick?: any;
  frontRealImage?: any;
}

const FaceContour = (props: IFaceClinicProps) => {
  const [isLoadingFace, setIsLoadingFace] = useState<boolean>(true);
  const [isOpenHelp, setIsOpenHelp] = useState<boolean>(false);

  //--- Canvas for face
  const canvasJawlineContour = useRef<HTMLCanvasElement>(null);
  const canvasSideFaceContour = useRef<HTMLCanvasElement>(null);
  const canvasForeheadContour = useRef<HTMLCanvasElement>(null);

  //--- Get data landmark
  const [dataFaceLandmark, setDataFaceLandmark] = useState<any>();

  const getDataLandMark = async () => {
    const landmark =
      props?.faceScanResult?.faceLandmarkResult?.dataFrontFaceLandmark;

    setDataFaceLandmark(landmark);

    setTimeout(() => {
      getDataDenseLandmark();
    }, 2000);
  };

  //--- Get data dense landmark
  const [dataFaceDenseLandmark, setDataFaceDenseLandmark] = useState<any>();

  const getDataDenseLandmark = async () => {
    const faceLandmark = props?.faceScanResult?.faceDenseLandmarkResult;
    setDataFaceDenseLandmark(faceLandmark);
    setIsLoadingFace(false);
  };

  //--- Load data face
  const onLoadDataFace = async () => {
    if (
      props.faceScanResult?.faceLandmarkResult?.dataFrontFaceLandmark &&
      props.faceScanResult?.faceLandmarkResult?.dataFrontFaceRectangle &&
      props.faceScanResult?.faceDenseLandmarkResult
    ) {
      setDataFaceLandmark(
        props.faceScanResult?.faceLandmarkResult?.dataFrontFaceLandmark
      );

      setDataFaceDenseLandmark(props.faceScanResult?.faceDenseLandmarkResult);

      setIsLoadingFace(false);
    } else {
      Promise.all([getDataLandMark()]);
    }
  };

  //--- Handle load image
  const [dimensionsRealImg, setDimensionsRealImg] = useState({
    width: 0,
    height: 0
  });
  const [dimensionsRenderImg, setDimensionsRenderImg] = useState({
    width: 0,
    height: 0
  });

  const getDimensionsRealImg = (url: string, cb: any) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  const handleImageLoad = (e: any) => {
    setTimeout(() => {
      const { width, height } = e.target;

      if (width > 0 && height > 0 && props.frontImage) {
        setDimensionsRenderImg({ width, height });

        getDimensionsRealImg(props.frontImage, (err: any, img: any) => {
          let imgRealWidth = Number(img?.width);
          let imageRealHeight = Number(img?.height);

          setDimensionsRealImg({
            width: imgRealWidth,
            height: imageRealHeight
          });

          if (props.frontImage && imgRealWidth > 0 && imageRealHeight > 0) {
            onLoadDataFace();
          }
        });
      }
    }, 2000);
  };

  useEffect(() => {
    if (
      dataFaceLandmark &&
      dataFaceDenseLandmark &&
      dimensionsRealImg.width > 0 &&
      dimensionsRenderImg.width > 0 &&
      dimensionsRenderImg.height > 0 &&
      props.frontImage
    ) {
      Promise.all([
        //--- Draw face contour
        drawFaceContour(
          dataFaceDenseLandmark,
          canvasJawlineContour,
          canvasSideFaceContour,
          canvasForeheadContour,
          dimensionsRealImg.width,
          dimensionsRenderImg.width,
          dimensionsRenderImg.height
        )
      ]);
    }
  }, [dataFaceLandmark, dataFaceDenseLandmark]);

  return (
    <>
      <div
        className={`${props.imgFront ? "grid grid-cols-2 gap-4" : "w-full"}`}
      >
        <div className={`${props.imgFront ? "w-fit" : ""}`}>
          <p className="text-purple-500 text-sm">{props.header}</p>
          <div className="relative">
            <div className="w-fit cursor-pointer mx-auto" onClick={props?.onClick}>
              <img
                src={props?.frontRealImage || props?.frontImage}
                alt="balance-face"
                className="max-w-full max-h-full"
                onError={(e: any) => (e.target.src = NotAvailableImg)}
                onLoad={handleImageLoad}
              />

              {/*--- Canvas face ---*/}
              <canvas
                ref={canvasJawlineContour}
                 className={`absolute top-0 left-0 right-0 left-1/2 transform -translate-x-1/2`}
              />

              <canvas
                ref={canvasSideFaceContour}
                 className={`absolute top-0 left-0 right-0 left-1/2 transform -translate-x-1/2`}
              />

              <canvas
                ref={canvasForeheadContour}
                 className={`absolute top-0 left-0 right-0 left-1/2 transform -translate-x-1/2`}
              />
            </div>

            <div
              className={`after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-black after:opacity-90 ${isLoadingFace ? "" : "hidden"
                }`}
            ></div>

            <div
              className={`absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 ${isLoadingFace ? "" : "hidden"
                }`}
            >
              <CircularProgress sx={{ color: "#ffffff" }} />
            </div>
          </div>
        </div>

        {props.afterComponent}
      </div>

      {props.imgFront && (
        <div className="mt-4">
          {/*--- Face contour ---*/}
          <ScanLevel
            showBefore={false}
            img={FaceIcon3}
            title="顔輪郭"
            onSetOpenHelp={setIsOpenHelp}
          />
        </div>
      )}

      {isOpenHelp && (
        <HelpModal
          isOpen={isOpenHelp}
          closeModal={() => setIsOpenHelp(false)}
          title="顔輪郭"
          content={
            <div>
              <p className="font-bold">【アドバイス】</p>
              顔輪郭をもとに、バランスに合った髪型の提案などができます。
              <br />
              <br />
              輪郭タイプ例：卵型（理想）・面長・逆三角形・ホームベース・丸・四角
              <br />
              <br />
              例）
              <br />
              卵型：比率が整っているため、どんなヘアスタイルも似合います。ショートからロングまで、前髪の有無も自由に選択可能。
              <br />
              <br />
              面長：横に広がりのあるレイヤースタイルで、縦のラインを軽減。シースルーバングで自然な額カバーを。
              <br />
              逆三角形：顎に向けて細くなるため、あご下のボリュームを出すミディアムやロングヘアがおすすめ。前髪は横に流して。
              <br />
              <br />
              ホームベース：顔の下半分が広いので、顔周りに軽いレイヤーを入れ、トップにボリュームを持たせたスタイルで調整を。
              <br />
              <br />
              丸型：トップにボリュームを出し、サイドは耳にかけてすっきりと。前髪は縦長効果のある斜めバングを。
              <br />
              四角：角張った印象を和らげるため、柔らかいレイヤーと流し前髪で優しい印象に。ストレートは避けて。
            </div>
          }
        />
      )}
    </>
  );
};

export default FaceContour;
