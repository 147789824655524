import React from "react";

interface InfoTabProps {
  onTabClick: (index: number) => void;
  activeTab: number;
  tab1: string;
  tab2?: string;
  tab3?: string;
  tab4?: string;
  tab5?: string | null;
  className?: string
}

const InfoTab: React.FC<InfoTabProps> = (props: InfoTabProps) => {
  return (
    <div className="border-b border-gray-200 dark:border-gray-700 overflow-x-scroll">
      <ul className={`flex flex-nowrap -mb-px text-sm font-medium text-center whitespace-nowrap overflow-x-auto justify-between ${props?.className || ''}`}>
        <li className="me-2" role="presentation">
          <button
            className={`inline-block py-4 rounded-t-lg ${props.activeTab === 0
              ? "border-b-4 border-purple-400"
              : "text-gray-300"
              }`}
            onClick={() => props.onTabClick(0)}
          >
            {props.tab1}
          </button>
        </li>
        <li className="me-2" role="presentation">
          <button
            className={`inline-block py-4 rounded-t-lg ${props.activeTab === 1
              ? "border-b-4 border-purple-400"
              : "text-gray-300"
              }`}
            onClick={() => props.onTabClick(1)}
          >
            {props.tab2}
          </button>
        </li>
        {props.tab3 && (
          <li className="me-2" role="presentation">
            <button
              className={`inline-block py-4 rounded-t-lg ${props.activeTab === 2
                ? "border-b-4 border-purple-400"
                : "text-gray-300"
                }`}
              onClick={() => props.onTabClick(2)}
            >
              {props.tab3}
            </button>
          </li>
        )}
        {props.tab4 && (
          <li className="me-2" role="presentation">
            <button
              className={`inline-block py-4 rounded-t-lg ${props.activeTab === 3
                ? "border-b-4 border-purple-400"
                : "text-gray-300"
                }`}
              onClick={() => props.onTabClick(3)}
            >
              {props.tab4}
            </button>
          </li>
        )}

        {props.tab5 && (
          <li className="me-2" role="presentation">
            <button
              className={`inline-block py-4 rounded-t-lg ${props.activeTab === 5
                ? "border-b-4 border-purple-400"
                : "text-gray-300"
                }`}
              onClick={() => props.onTabClick(5)}
            >
              {props.tab5}
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default InfoTab;
