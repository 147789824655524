import {
  calculateHeadTiltedPoint,
  convertToCoordinatesPoint,
  drawCircle,
  drawDashLine,
  drawFacePoint,
  drawLine,
  getFacePoint,
  getTrianglePoint,
  middlePoint
} from "../utils/face-analysis";
import { drawLeftEyeBox, drawRightEyeBox } from "./eye-service";
import { calculateJunctionPoint } from "./junction-line-service";
import { noseRatioService } from "./nose-service";

const drawHorizontalBalanceLine = (
  dataFaceLandmark: any,
  dataFaceDenseLandmark: any,
  canvasHorizontalBalance: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceLandmark && dataFaceDenseLandmark) {
    const imageScale = imgRealWidth / imgRenderWidth;

    //const faceHairLine = (dataFaceDenseLandmark?.face?.face_hairline_74.y / imageScale);
    //const leftEyeTopPint = { x: dataFaceLandmark.left_eye_top.x, y: dataFaceLandmark.left_eye_top.y / imageScale };
    //const contourChinLine = (dataFaceLandmark.contour_chin.y / imageScale);

    const { face_hairline_74 } = dataFaceDenseLandmark?.face;
    const {
      left_eyebrow_upper_middle,
      right_eyebrow_upper_middle,
      left_eye_top,
      right_eye_top,
      nose_contour_lower_middle,
      contour_chin
    } = dataFaceLandmark;

    const x1 = left_eye_top.x;
    const y1 = left_eye_top.y;
    const x2 = right_eye_top.x;
    const y2 = right_eye_top.y;

    const faceHairLine_D = calculateHeadTiltedPoint(
      x1,
      y1,
      x2,
      y2,
      face_hairline_74.x,
      face_hairline_74.y
    );
    const noseContourLower_D = calculateHeadTiltedPoint(
      x1,
      y1,
      x2,
      y2,
      nose_contour_lower_middle.x,
      nose_contour_lower_middle.y
    );
    const contourChinRight_D = calculateHeadTiltedPoint(
      x1,
      y1,
      x2,
      y2,
      contour_chin.x,
      contour_chin.y
    );

    const _horizontalLine1 = {
      moveX:
        faceHairLine_D.x / imageScale -
        (face_hairline_74.x / imageScale - faceHairLine_D.x / imageScale) * 100,
      moveY:
        faceHairLine_D.y / imageScale -
        (face_hairline_74.y / imageScale - faceHairLine_D.y / imageScale) * 100,
      lineToX:
        face_hairline_74.x / imageScale -
        (faceHairLine_D.x / imageScale - face_hairline_74.x / imageScale) * 100,
      lineToY:
        face_hairline_74.y / imageScale -
        (faceHairLine_D.y / imageScale - face_hairline_74.y / imageScale) * 100
    };

    const _horizontalLine2 = {
      moveX:
        left_eyebrow_upper_middle.x / imageScale -
        (right_eyebrow_upper_middle.x / imageScale -
          left_eyebrow_upper_middle.x / imageScale) *
          100,
      moveY:
        left_eyebrow_upper_middle.y / imageScale -
        (right_eyebrow_upper_middle.y / imageScale -
          left_eyebrow_upper_middle.y / imageScale) *
          100,
      lineToX:
        right_eyebrow_upper_middle.x / imageScale -
        (left_eyebrow_upper_middle.x / imageScale -
          right_eyebrow_upper_middle.x / imageScale) *
          100,
      lineToY:
        right_eyebrow_upper_middle.y / imageScale -
        (left_eyebrow_upper_middle.y / imageScale -
          right_eyebrow_upper_middle.y / imageScale) *
          100
    };

    const _horizontalLine3 = {
      moveX:
        noseContourLower_D.x / imageScale -
        (nose_contour_lower_middle.x / imageScale -
          noseContourLower_D.x / imageScale) *
          100,
      moveY:
        noseContourLower_D.y / imageScale -
        (nose_contour_lower_middle.y / imageScale -
          noseContourLower_D.y / imageScale) *
          100,
      lineToX:
        nose_contour_lower_middle.x / imageScale -
        (noseContourLower_D.x / imageScale -
          nose_contour_lower_middle.x / imageScale) *
          100,
      lineToY:
        nose_contour_lower_middle.y / imageScale -
        (noseContourLower_D.y / imageScale -
          nose_contour_lower_middle.y / imageScale) *
          100
    };

    const _horizontalLine4 = {
      moveX:
        contourChinRight_D.x / imageScale -
        (contour_chin.x / imageScale - contourChinRight_D.x / imageScale) * 100,
      moveY:
        contourChinRight_D.y / imageScale -
        (contour_chin.y / imageScale - contourChinRight_D.y / imageScale) * 100,
      lineToX:
        contour_chin.x / imageScale -
        (contourChinRight_D.x / imageScale - contour_chin.x / imageScale) * 100,
      lineToY:
        contour_chin.y / imageScale -
        (contourChinRight_D.y / imageScale - contour_chin.y / imageScale) * 100
    };

    if (canvasHorizontalBalance.current) {
      canvasHorizontalBalance.current.width = imgRenderWidth;
      canvasHorizontalBalance.current.height = imgRenderHeight;

      const ctxHorizontalBalance =
        canvasHorizontalBalance.current.getContext("2d");

      if (ctxHorizontalBalance) {
        drawLine(ctxHorizontalBalance, _horizontalLine1, { color: "#33B294" });
        drawLine(ctxHorizontalBalance, _horizontalLine2, { color: "#33B294" });
        drawLine(ctxHorizontalBalance, _horizontalLine3, { color: "#33B294" });
        drawLine(ctxHorizontalBalance, _horizontalLine4, { color: "#33B294" });
      }
    }
  }
};

const drawVerticalBalanceLine = (
  dataFaceLandmark: any,
  dataFaceDenseLandmark: any,
  canvasVerticalBalance: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceLandmark && dataFaceDenseLandmark) {
    const imageScale = imgRealWidth / imgRenderWidth;

    //const faceContourLeft63Pint = (dataFaceDenseLandmark.face.face_contour_left_63.x / imageScale);
    //const leftEyeLeftCornerPint = (dataFaceLandmark.left_eye_left_corner.x / imageScale);
    //const noseLeftPint = (dataFaceLandmark.nose_left.x / imageScale);
    //const noseRightPint = (dataFaceLandmark.nose_right.x / imageScale);
    //const rightEyeRightCornerPint = (dataFaceLandmark.right_eye_right_corner.x / imageScale);
    //const contourRightPint = (dataFaceLandmark.contour_right1.x / imageScale);

    const x1 = dataFaceLandmark.nose_tip.x;
    const y1 = dataFaceLandmark.nose_tip.y;
    const x2 = dataFaceLandmark.contour_chin.x;
    const y2 = dataFaceLandmark.contour_chin.y;

    const {
      left_eye_left_corner,
      nose_left,
      nose_right,
      right_eye_right_corner,
      contour_right2
    } = dataFaceLandmark;
    const {
      face: { face_contour_left_63 }
    } = dataFaceDenseLandmark;
    const faceContourLeft63Pint_D = calculateHeadTiltedPoint(
      x1,
      y1,
      x2,
      y2,
      face_contour_left_63.x,
      face_contour_left_63.y
    );
    const leftEyeLeftCornerPint_D = calculateHeadTiltedPoint(
      x1,
      y1,
      x2,
      y2,
      left_eye_left_corner.x,
      left_eye_left_corner.y
    );
    const noseLeftPint_D = calculateHeadTiltedPoint(
      x1,
      y1,
      x2,
      y2,
      nose_left.x,
      nose_left.y
    );
    const noseRightPint_D = calculateHeadTiltedPoint(
      x1,
      y1,
      x2,
      y2,
      nose_right.x,
      nose_right.y
    );
    const rightEyeRightCornerPint_D = calculateHeadTiltedPoint(
      x1,
      y1,
      x2,
      y2,
      right_eye_right_corner.x,
      right_eye_right_corner.y
    );
    const contourRightPint_D = calculateHeadTiltedPoint(
      x1,
      y1,
      x2,
      y2,
      contour_right2.x,
      contour_right2.y
    );

    const _verticalLine1 = {
      moveX:
        faceContourLeft63Pint_D.x / imageScale -
        (face_contour_left_63.x / imageScale -
          faceContourLeft63Pint_D.x / imageScale) *
          100,
      moveY:
        faceContourLeft63Pint_D.y / imageScale -
        (face_contour_left_63.y / imageScale -
          faceContourLeft63Pint_D.y / imageScale) *
          100,
      lineToX:
        face_contour_left_63.x / imageScale -
        (faceContourLeft63Pint_D.x / imageScale -
          face_contour_left_63.x / imageScale) *
          100,
      lineToY:
        face_contour_left_63.y / imageScale -
        (faceContourLeft63Pint_D.y / imageScale -
          face_contour_left_63.y / imageScale) *
          100
    };

    const _verticalLine2 = {
      moveX:
        leftEyeLeftCornerPint_D.x / imageScale -
        (left_eye_left_corner.x / imageScale -
          leftEyeLeftCornerPint_D.x / imageScale) *
          100,
      moveY:
        leftEyeLeftCornerPint_D.y / imageScale -
        (left_eye_left_corner.y / imageScale -
          leftEyeLeftCornerPint_D.y / imageScale) *
          100,
      lineToX:
        left_eye_left_corner.x / imageScale -
        (leftEyeLeftCornerPint_D.x / imageScale -
          left_eye_left_corner.x / imageScale) *
          100,
      lineToY:
        left_eye_left_corner.y / imageScale -
        (leftEyeLeftCornerPint_D.y / imageScale -
          left_eye_left_corner.y / imageScale) *
          100
    };

    const _verticalLine3 = {
      moveX:
        noseLeftPint_D.x / imageScale -
        (nose_left.x / imageScale - noseLeftPint_D.x / imageScale) * 100,
      moveY:
        noseLeftPint_D.y / imageScale -
        (nose_left.y / imageScale - noseLeftPint_D.y / imageScale) * 100,
      lineToX:
        nose_left.x / imageScale -
        (noseLeftPint_D.x / imageScale - nose_left.x / imageScale) * 100,
      lineToY:
        nose_left.y / imageScale -
        (noseLeftPint_D.y / imageScale - nose_left.y / imageScale) * 100
    };

    const _verticalLine4 = {
      moveX:
        noseRightPint_D.x / imageScale -
        (nose_right.x / imageScale - noseRightPint_D.x / imageScale) * 100,
      moveY:
        noseRightPint_D.y / imageScale -
        (nose_right.y / imageScale - noseRightPint_D.y / imageScale) * 100,
      lineToX:
        nose_right.x / imageScale -
        (noseRightPint_D.x / imageScale - nose_right.x / imageScale) * 100,
      lineToY:
        nose_right.y / imageScale -
        (noseRightPint_D.y / imageScale - nose_right.y / imageScale) * 100
    };

    const _verticalLine5 = {
      moveX:
        rightEyeRightCornerPint_D.x / imageScale -
        (right_eye_right_corner.x / imageScale -
          rightEyeRightCornerPint_D.x / imageScale) *
          100,
      moveY:
        rightEyeRightCornerPint_D.y / imageScale -
        (right_eye_right_corner.y / imageScale -
          rightEyeRightCornerPint_D.y / imageScale) *
          100,
      lineToX:
        right_eye_right_corner.x / imageScale -
        (rightEyeRightCornerPint_D.x / imageScale -
          right_eye_right_corner.x / imageScale) *
          100,
      lineToY:
        right_eye_right_corner.y / imageScale -
        (rightEyeRightCornerPint_D.y / imageScale -
          right_eye_right_corner.y / imageScale) *
          100
    };

    const _verticalLine6 = {
      moveX:
        contourRightPint_D.x / imageScale -
        (contour_right2.x / imageScale - contourRightPint_D.x / imageScale) *
          100,
      moveY:
        contourRightPint_D.y / imageScale -
        (contour_right2.y / imageScale - contourRightPint_D.y / imageScale) *
          100,
      lineToX:
        contour_right2.x / imageScale -
        (contourRightPint_D.x / imageScale - contour_right2.x / imageScale) *
          100,
      lineToY:
        contour_right2.y / imageScale -
        (contourRightPint_D.y / imageScale - contour_right2.y / imageScale) *
          100
    };

    if (canvasVerticalBalance.current) {
      canvasVerticalBalance.current.width = imgRenderWidth;
      canvasVerticalBalance.current.height = imgRenderHeight;

      const ctxVerticalBalance = canvasVerticalBalance.current.getContext("2d");

      if (ctxVerticalBalance) {
        drawLine(ctxVerticalBalance, _verticalLine1, { color: "#33B294" });
        drawLine(ctxVerticalBalance, _verticalLine2, { color: "#33B294" });
        drawLine(ctxVerticalBalance, _verticalLine3, { color: "#33B294" });
        drawLine(ctxVerticalBalance, _verticalLine4, { color: "#33B294" });
        drawLine(ctxVerticalBalance, _verticalLine5, { color: "#33B294" });
        drawLine(ctxVerticalBalance, _verticalLine6, { color: "#33B294" });
      }
    }
  }
};

const drawJunctionLine = (
  dataFaceLandmark: any,
  dataFaceDenseLandmark: any,
  canvasJunction: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceLandmark && dataFaceDenseLandmark) {
    const imageScale = imgRealWidth / imgRenderWidth;

    //const faceContourLeft63Pint = (dataFaceDenseLandmark.face.face_contour_left_63.x / imageScale);
    //const contourRightPint = (dataFaceLandmark.contour_right1.x / imageScale);
    //const noseContourLowerMiddlePint = (dataFaceLandmark.nose_contour_lower_middle.x / imageScale);
    //const leftEyeBottomPint = (dataFaceLandmark.left_eye_bottom.y / imageScale);
    //const faceHairLine = (dataFaceDenseLandmark?.face?.face_hairline_74.y / imageScale);
    //const contourChinLine = (dataFaceLandmark.contour_chin.y / imageScale);

    const {
      contour_left1,
      contour_right1,
      contour_chin,
      nose_tip,
      left_eye_bottom,
      right_eye_bottom
    } = dataFaceLandmark;
    const {
      face: { face_hairline_74 }
    } = dataFaceDenseLandmark;

    const serviceResults = calculateJunctionPoint({
      contour_left1,
      contour_right1,
      contour_chin,
      face_hairline_74,
      nose_tip,
      left_eye_bottom,
      right_eye_bottom
    });

    const _verticalLine1 = {
      moveX: serviceResults.top.x / imageScale,
      moveY: serviceResults.top.y / imageScale,
      lineToX: serviceResults.bottom.x / imageScale,
      lineToY: serviceResults.bottom.y / imageScale
    };

    const _horizontalLine1 = {
      moveX: serviceResults.left.x / imageScale,
      moveY: serviceResults.left.y / imageScale,
      lineToX: serviceResults.right.x / imageScale,
      lineToY: serviceResults.right.y / imageScale
    };

    if (canvasJunction.current) {
      canvasJunction.current.width = imgRenderWidth;
      canvasJunction.current.height = imgRenderHeight;

      const ctxJunction = canvasJunction.current.getContext("2d");

      if (ctxJunction) {
        drawDashLine(ctxJunction, _verticalLine1, {
          width: 5,
          color: "#FFFFFF"
        });
        drawDashLine(ctxJunction, _horizontalLine1, {
          width: 5,
          color: "#FFFFFF"
        });
      }
    }
  }
};

const drawFacialLaterality = (
  dataFaceLandmark: any,
  canvasFacialLaterality: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceLandmark) {
    const imageScale = imgRealWidth / imgRenderWidth;

    // const leftEyeTopPint = { x: dataFaceLandmark.left_eye_top.x, y: dataFaceLandmark.left_eye_top.y };
    // const rightEyeTopPint = { x: dataFaceLandmark.right_eye_top.x, y: dataFaceLandmark.right_eye_top.y };
    // const leftEyeBottomPint = { x: dataFaceLandmark.left_eye_bottom.x, y: dataFaceLandmark.left_eye_bottom.y };
    // const rightEyeBottomPint = { x: dataFaceLandmark.right_eye_bottom.x, y: dataFaceLandmark.right_eye_bottom.y };
    // const noseContourLeft1Pint = { x: dataFaceLandmark.nose_contour_left1.x, y: dataFaceLandmark.nose_contour_left1.y };
    // const noseContourRight1Pint = {
    //   x: dataFaceLandmark.nose_contour_right1.x,
    //   y: dataFaceLandmark.nose_contour_right1.y
    // };
    // const noseContourLowerMiddleLine = {
    //   x: dataFaceLandmark.nose_contour_lower_middle.x,
    //   y: dataFaceLandmark.nose_contour_lower_middle.y
    // };
    // const noseContourLeft3Pint = { x: dataFaceLandmark.nose_contour_left3.x, y: dataFaceLandmark.nose_contour_left3.y };
    // const noseContourRight3Pint = {
    //   x: dataFaceLandmark.nose_contour_right3.x,
    //   y: dataFaceLandmark.nose_contour_right3.y
    // };

    const {
      left_eye_top,
      right_eye_top,
      left_eye_bottom,
      right_eye_bottom,
      nose_contour_lower_middle
    } = dataFaceLandmark;

    const x1 = left_eye_top.x;
    const y1 = left_eye_top.y;
    const x2 = right_eye_top.x;
    const y2 = right_eye_top.y;
    const x3 = left_eye_bottom.x;
    const y3 = left_eye_bottom.y;
    const x4 = right_eye_bottom.x;
    const y4 = right_eye_bottom.y;

    // A point of the bottom left eye
    const temp3 = calculateHeadTiltedPoint(x1, y1, x2, y2, x3, y3);
    // D point of the bottom right eye
    const temp4 = calculateHeadTiltedPoint(x2, y2, x1, y1, x4, y4);

    const _horizontalLine1 = {
      moveX: x1 / imageScale - (x1 / imageScale - x2 / imageScale) * 0.2,
      moveY: y1 / imageScale - (y1 / imageScale - y2 / imageScale) * 0.2,
      lineToX: x1 / imageScale - (x2 / imageScale - x1 / imageScale) * 100,
      lineToY: y1 / imageScale - (y2 / imageScale - y1 / imageScale) * 100
    };

    const _horizontalLine2 = {
      moveX: x2 / imageScale - (x2 / imageScale - x1 / imageScale) * 0.2,
      moveY: y2 / imageScale - (y2 / imageScale - y1 / imageScale) * 0.2,
      lineToX: x2 / imageScale - (x1 / imageScale - x2 / imageScale) * 100,
      lineToY: y2 / imageScale - (y1 / imageScale - y2 / imageScale) * 100
    };

    const _horizontalLine3 = {
      moveX: x3 / imageScale - (x3 / imageScale - temp3.x / imageScale) * 0.2,
      moveY: y3 / imageScale - (y3 / imageScale - temp3.y / imageScale) * 0.2,
      lineToX: x3 / imageScale - (temp3.x / imageScale - x3 / imageScale) * 100,
      lineToY: y3 / imageScale - (temp3.y / imageScale - y3 / imageScale) * 100
    };

    const _horizontalLine4 = {
      moveX: x4 / imageScale - (x4 / imageScale - temp4.x / imageScale) * 0.2,
      moveY: y4 / imageScale - (y4 / imageScale - temp4.y / imageScale) * 0.2,
      lineToX: x4 / imageScale - (temp4.x / imageScale - x4 / imageScale) * 100,
      lineToY: y4 / imageScale - (temp4.y / imageScale - y4 / imageScale) * 100
    };

    const noseContourLowerMiddle_D = calculateHeadTiltedPoint(
      x2,
      y2,
      x1,
      y1,
      nose_contour_lower_middle.x,
      nose_contour_lower_middle.y
    );

    const _horizontalLine5 = {
      moveX:
        noseContourLowerMiddle_D.x / imageScale -
        (noseContourLowerMiddle_D.x / imageScale -
          nose_contour_lower_middle.x / imageScale) *
          0.6,
      moveY:
        noseContourLowerMiddle_D.y / imageScale -
        (noseContourLowerMiddle_D.y / imageScale -
          nose_contour_lower_middle.y / imageScale) *
          0.6,
      lineToX:
        nose_contour_lower_middle.x / imageScale +
        (noseContourLowerMiddle_D.x / imageScale -
          nose_contour_lower_middle.x / imageScale) *
          20,
      lineToY:
        nose_contour_lower_middle.y / imageScale +
        (noseContourLowerMiddle_D.y / imageScale -
          nose_contour_lower_middle.y / imageScale) *
          20
    };

    const _horizontalLine6 = {
      moveX:
        nose_contour_lower_middle.x / imageScale +
        (nose_contour_lower_middle.x / imageScale -
          noseContourLowerMiddle_D.x / imageScale) *
          0.4,
      moveY:
        nose_contour_lower_middle.y / imageScale +
        (nose_contour_lower_middle.y / imageScale -
          noseContourLowerMiddle_D.y / imageScale) *
          0.4,
      lineToX:
        noseContourLowerMiddle_D.x / imageScale +
        (nose_contour_lower_middle.x / imageScale -
          noseContourLowerMiddle_D.x / imageScale) *
          20,
      lineToY:
        noseContourLowerMiddle_D.y / imageScale +
        (nose_contour_lower_middle.y / imageScale -
          noseContourLowerMiddle_D.y / imageScale) *
          20
    };

    if (canvasFacialLaterality.current) {
      canvasFacialLaterality.current.width = imgRenderWidth;
      canvasFacialLaterality.current.height = imgRenderHeight;

      const ctxFacialLaterality =
        canvasFacialLaterality.current.getContext("2d");

      if (ctxFacialLaterality) {
        drawLine(ctxFacialLaterality, _horizontalLine1, {
          color: "#33B294",
          width: 1
        });
        drawLine(ctxFacialLaterality, _horizontalLine2, {
          color: "#33B294",
          width: 1
        });
        drawLine(ctxFacialLaterality, _horizontalLine3, {
          color: "#33B294",
          width: 1
        });
        drawLine(ctxFacialLaterality, _horizontalLine4, {
          color: "#33B294",
          width: 1
        });
        drawLine(ctxFacialLaterality, _horizontalLine5, {
          color: "#33B294",
          width: 1
        });
        drawLine(ctxFacialLaterality, _horizontalLine6, {
          color: "#33B294",
          width: 1
        });
      }
    }
  }
};

const drawFaceRatio = (
  dataFaceLandmark: any,
  dataFaceDenseLandmark: any,
  canvasFaceRatio: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceLandmark && dataFaceDenseLandmark) {
    const imageScale = imgRealWidth / imgRenderWidth;
    const {
      contour_chin,
      contour_right2,
      left_eye_right_corner,
      right_eye_left_corner
    } = dataFaceLandmark;
    const centrePoint = middlePoint(
      left_eye_right_corner,
      right_eye_left_corner
    );
    const x1 = centrePoint.x;
    const y1 = centrePoint.y;
    const x2 = contour_chin.x;
    const y2 = contour_chin.y;
    const x3 = contour_right2.x;
    const y3 = contour_right2.y;

    const D = calculateHeadTiltedPoint(x1, y1, x2, y2, x3, y3);

    const _verticalLine1 = {
      moveX: D.x / imageScale,
      moveY: D.y / imageScale,
      lineToX:
        contour_right2.x / imageScale +
        (contour_right2.x / imageScale - D.x / imageScale),
      lineToY:
        contour_right2.y / imageScale +
        (contour_right2.y / imageScale - D.y / imageScale)
    };

    const _horizontalLine1 = {
      moveX:
        contour_chin.x / imageScale -
        (D.x / imageScale - contour_chin.x / imageScale),
      moveY:
        contour_chin.y / imageScale -
        (D.y / imageScale - contour_chin.y / imageScale),
      lineToX: D.x / imageScale,
      lineToY: D.y / imageScale
    };

    if (canvasFaceRatio.current) {
      canvasFaceRatio.current.width = imgRenderWidth;
      canvasFaceRatio.current.height = imgRenderHeight;

      const ctxFaceRatio = canvasFaceRatio.current.getContext("2d");

      if (ctxFaceRatio) {
        drawLine(ctxFaceRatio, _verticalLine1, { color: "#33B294", width: 1 });
        drawLine(ctxFaceRatio, _horizontalLine1, {
          color: "#33B294",
          width: 1
        });
      }
    }
  }
};

const drawTriangleLine = (
  dataFaceLandmark: any,
  canvasTriangle: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceLandmark) {
    const imageScale = imgRealWidth / imgRenderWidth;

    const { firstPint, secondPint, thirdPint } =
      getTrianglePoint(dataFaceLandmark);

    if (canvasTriangle.current) {
      canvasTriangle.current.width = imgRenderWidth;
      canvasTriangle.current.height = imgRenderHeight;

      const ctxTriangle = canvasTriangle.current.getContext("2d");
      if (ctxTriangle) {
        ctxTriangle.beginPath();
        ctxTriangle.moveTo(firstPint.x / imageScale, firstPint.y / imageScale);
        ctxTriangle.lineTo(
          secondPint.x / imageScale,
          secondPint.y / imageScale
        );
        ctxTriangle.lineTo(thirdPint.x / imageScale, thirdPint.y / imageScale);
        ctxTriangle.lineTo(firstPint.x / imageScale, firstPint.y / imageScale);
        ctxTriangle.strokeStyle = "#33B294";
        ctxTriangle.lineWidth = 1;
        ctxTriangle.stroke();
      }
    }
  }
};

const drawTrapezoidalArea = (
  dataFaceLandmark: any,
  canvasTrapezoidalArea: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceLandmark) {
    const imageScale = imgRealWidth / imgRenderWidth;

    const contourLeft3Pint = {
      x: dataFaceLandmark.contour_left3.x,
      y: dataFaceLandmark.contour_left3.y
    };
    const contourRight3Pint = {
      x: dataFaceLandmark.contour_right3.x,
      y: dataFaceLandmark.contour_right3.y
    };
    const contourRight8Pint = {
      x: dataFaceLandmark.contour_right8.x,
      y: dataFaceLandmark.contour_right8.y
    };
    const contourLeft8Pint = {
      x: dataFaceLandmark.contour_left8.x,
      y: dataFaceLandmark.contour_left8.y
    };

    if (canvasTrapezoidalArea.current) {
      canvasTrapezoidalArea.current.width = imgRenderWidth;
      canvasTrapezoidalArea.current.height = imgRenderHeight;

      const ctxTrapezoidalArea = canvasTrapezoidalArea.current.getContext("2d");
      if (ctxTrapezoidalArea) {
        ctxTrapezoidalArea.beginPath();
        ctxTrapezoidalArea.moveTo(
          contourLeft3Pint.x / imageScale,
          contourLeft3Pint.y / imageScale
        );
        ctxTrapezoidalArea.lineTo(
          contourRight3Pint.x / imageScale,
          contourRight3Pint.y / imageScale
        );
        ctxTrapezoidalArea.lineTo(
          contourRight8Pint.x / imageScale,
          contourRight8Pint.y / imageScale
        );
        ctxTrapezoidalArea.lineTo(
          contourLeft8Pint.x / imageScale,
          contourLeft8Pint.y / imageScale
        );
        ctxTrapezoidalArea.lineTo(
          contourLeft3Pint.x / imageScale,
          contourLeft3Pint.y / imageScale
        );
        ctxTrapezoidalArea.strokeStyle = "#33B294";
        ctxTrapezoidalArea.lineWidth = 1;
        ctxTrapezoidalArea.stroke();
      }
    }
  }
};

const drawFaceContour = (
  dataFaceDenseLandmark: any,
  canvasJawlineContour: any,
  canvasSideFaceContour: any,
  canvasForeheadContour: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceDenseLandmark) {
    const imageScale = imgRealWidth / imgRenderWidth;

    const { jawlinePoint, sideFacePoint, foreheadRimPoint } = getFacePoint(
      dataFaceDenseLandmark.face
    );

    if (canvasJawlineContour.current) {
      canvasJawlineContour.current.width = imgRenderWidth;
      canvasJawlineContour.current.height = imgRenderHeight;

      const ctxJawlineContour = canvasJawlineContour.current.getContext("2d");

      if (ctxJawlineContour) {
        drawFacePoint(jawlinePoint, imageScale, ctxJawlineContour);
      }
    }

    if (canvasSideFaceContour.current) {
      canvasSideFaceContour.current.width = imgRenderWidth;
      canvasSideFaceContour.current.height = imgRenderHeight;

      const ctxSideFaceContour = canvasSideFaceContour.current.getContext("2d");

      if (ctxSideFaceContour) {
        drawFacePoint(sideFacePoint, imageScale, ctxSideFaceContour);
      }
    }

    if (canvasForeheadContour.current) {
      canvasForeheadContour.current.width = imgRenderWidth;
      canvasForeheadContour.current.height = imgRenderHeight;

      const ctxForeheadContour = canvasForeheadContour.current.getContext("2d");

      if (ctxForeheadContour) {
        drawFacePoint(foreheadRimPoint, imageScale, ctxForeheadContour);
      }
    }
  }
};

const drawEyeContour = (
  dataFaceDenseLandmark: any,
  canvasEyeContour: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceDenseLandmark) {
    const imageScale = imgRealWidth / imgRenderWidth;

    const { left_eye, right_eye, left_eye_eyelid, right_eye_eyelid } =
      dataFaceDenseLandmark;

    if (canvasEyeContour.current) {
      canvasEyeContour.current.width = imgRenderWidth;
      canvasEyeContour.current.height = imgRenderHeight;

      const ctxEyeContour = canvasEyeContour.current.getContext("2d");

      if (ctxEyeContour) {
        drawFacePoint(
          convertToCoordinatesPoint(left_eye),
          imageScale,
          ctxEyeContour
        );
        drawFacePoint(
          convertToCoordinatesPoint(left_eye_eyelid),
          imageScale,
          ctxEyeContour
        );
        drawFacePoint(
          convertToCoordinatesPoint(right_eye),
          imageScale,
          ctxEyeContour
        );
        drawFacePoint(
          convertToCoordinatesPoint(right_eye_eyelid),
          imageScale,
          ctxEyeContour
        );
      }
    }
  }
};

const drawEyeAngle = (
  dataFaceLandmark: any,
  canvasEyeAngle: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceLandmark) {
    const imageScale = imgRealWidth / imgRenderWidth;

    const rightEyeLeftCornerPint = {
      x: dataFaceLandmark.right_eye_left_corner.x,
      y: dataFaceLandmark.right_eye_left_corner.y
    };
    const rightEyeRightCornerPint = {
      x: dataFaceLandmark.right_eye_right_corner.x,
      y: dataFaceLandmark.right_eye_right_corner.y
    };
    const rightEyeBottomPint = {
      x: dataFaceLandmark.right_eye_bottom.x,
      y: dataFaceLandmark.right_eye_bottom.y
    };

    const _eyeAngleLine1 = {
      moveX: rightEyeLeftCornerPint.x / imageScale,
      moveY: rightEyeLeftCornerPint.y / imageScale,
      lineToX: rightEyeRightCornerPint.x / imageScale,
      lineToY: rightEyeRightCornerPint.y / imageScale
    };
    const _eyeAngleLine2 = {
      moveX: rightEyeLeftCornerPint.x / imageScale,
      moveY: rightEyeLeftCornerPint.y / imageScale,
      lineToX: rightEyeBottomPint.x / imageScale,
      lineToY: rightEyeBottomPint.y / imageScale
    };

    const newPointRightX =
      rightEyeBottomPint.x -
      (rightEyeLeftCornerPint.x - rightEyeBottomPint.x) * 0.9;
    const newPointRightY =
      rightEyeBottomPint.y -
      (rightEyeLeftCornerPint.y - rightEyeBottomPint.y) * 0.9;

    const _eyeAngleLine3 = {
      moveX: rightEyeBottomPint.x / imageScale,
      moveY: rightEyeBottomPint.y / imageScale,
      lineToX: newPointRightX / imageScale,
      lineToY: newPointRightY / imageScale
    };

    const leftEyeLeftCornerPint = {
      x: dataFaceLandmark.left_eye_left_corner.x,
      y: dataFaceLandmark.left_eye_left_corner.y
    };
    const leftEyeRightCornerPint = {
      x: dataFaceLandmark.left_eye_right_corner.x,
      y: dataFaceLandmark.left_eye_right_corner.y
    };
    const leftEyeBottomPint = {
      x: dataFaceLandmark.left_eye_bottom.x,
      y: dataFaceLandmark.left_eye_bottom.y
    };

    const _eyeAngleLine4 = {
      moveX: leftEyeRightCornerPint.x / imageScale,
      moveY: leftEyeRightCornerPint.y / imageScale,
      lineToX: leftEyeLeftCornerPint.x / imageScale,
      lineToY: leftEyeLeftCornerPint.y / imageScale
    };
    const _eyeAngleLine5 = {
      moveX: leftEyeRightCornerPint.x / imageScale,
      moveY: leftEyeRightCornerPint.y / imageScale,
      lineToX: leftEyeBottomPint.x / imageScale,
      lineToY: leftEyeBottomPint.y / imageScale
    };

    const newPointLeftX =
      leftEyeBottomPint.x -
      (leftEyeRightCornerPint.x - leftEyeBottomPint.x) * 0.9;
    const newPointLeftY =
      leftEyeBottomPint.y -
      (leftEyeRightCornerPint.y - leftEyeBottomPint.y) * 0.9;

    const _eyeAngleLine6 = {
      moveX: leftEyeBottomPint.x / imageScale,
      moveY: leftEyeBottomPint.y / imageScale,
      lineToX: newPointLeftX / imageScale,
      lineToY: newPointLeftY / imageScale
    };

    if (canvasEyeAngle.current) {
      canvasEyeAngle.current.width = imgRenderWidth;
      canvasEyeAngle.current.height = imgRenderHeight;

      const ctxEyeAngle = canvasEyeAngle.current.getContext("2d");

      if (ctxEyeAngle) {
        drawDashLine(ctxEyeAngle, _eyeAngleLine1, {
          color: "#FFFFFF",
          width: 1
        });
        drawDashLine(ctxEyeAngle, _eyeAngleLine2, {
          color: "#FFFFFF",
          width: 1
        });
        drawDashLine(ctxEyeAngle, _eyeAngleLine3, {
          color: "#FFFFFF",
          width: 1
        });

        drawDashLine(ctxEyeAngle, _eyeAngleLine4, {
          color: "#FFFFFF",
          width: 1
        });
        drawDashLine(ctxEyeAngle, _eyeAngleLine5, {
          color: "#FFFFFF",
          width: 1
        });
        drawDashLine(ctxEyeAngle, _eyeAngleLine6, {
          color: "#FFFFFF",
          width: 1
        });
      }
    }
  }
};

const drawEyeRatio = (
  dataFaceLandmark: any,
  canvasEyeRatio: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceLandmark) {
    const imageScale = imgRealWidth / imgRenderWidth;

    const {
      left_eye_left_corner,
      left_eye_right_corner,
      left_eye_top,
      left_eye_bottom,
      right_eye_left_corner,
      right_eye_right_corner,
      right_eye_top,
      right_eye_bottom
    } = dataFaceLandmark;

    //Draw box of left eyes
    const {
      A: aLeft,
      B: bLeft,
      C: cLeft,
      D: dLeft
    } = drawLeftEyeBox({
      left_eye_left_corner,
      left_eye_right_corner,
      left_eye_top,
      left_eye_bottom
    });

    // Right side of left eye
    const _line1 = {
      moveX: aLeft.x / imageScale,
      moveY: aLeft.y / imageScale,
      lineToX: bLeft.x / imageScale,
      lineToY: bLeft.y / imageScale
    };

    // Underline of left eye
    const _line2 = {
      moveX: aLeft.x / imageScale,
      moveY: aLeft.y / imageScale,
      lineToX: cLeft.x / imageScale,
      lineToY: cLeft.y / imageScale
    };

    // Left side of left eye
    const _line3 = {
      moveX: cLeft.x / imageScale,
      moveY: cLeft.y / imageScale,
      lineToX: dLeft.x / imageScale,
      lineToY: dLeft.y / imageScale
    };

    // Top side of left eye
    const _line4 = {
      moveX: bLeft.x / imageScale,
      moveY: bLeft.y / imageScale,
      lineToX: dLeft.x / imageScale,
      lineToY: dLeft.y / imageScale
    };

    //Draw box of right eyes
    const {
      A: aRight,
      B: bRight,
      C: cRight,
      D: dRight
    } = drawRightEyeBox({
      right_eye_left_corner,
      right_eye_right_corner,
      right_eye_top,
      right_eye_bottom
    });

    // Right side of right eye
    const _line1Right = {
      moveX: aRight.x / imageScale,
      moveY: aRight.y / imageScale,
      lineToX: bRight.x / imageScale,
      lineToY: bRight.y / imageScale
    };

    // Underline of right eye
    const _line2Right = {
      moveX: aRight.x / imageScale,
      moveY: aRight.y / imageScale,
      lineToX: cRight.x / imageScale,
      lineToY: cRight.y / imageScale
    };

    // Top of right eye
    const _line3Right = {
      moveX: bRight.x / imageScale,
      moveY: bRight.y / imageScale,
      lineToX: dRight.x / imageScale,
      lineToY: dRight.y / imageScale
    };

    // Left side of right eye
    const _line4Right = {
      moveX: cRight.x / imageScale,
      moveY: cRight.y / imageScale,
      lineToX: dRight.x / imageScale,
      lineToY: dRight.y / imageScale
    };

    if (canvasEyeRatio.current) {
      canvasEyeRatio.current.width = imgRenderWidth;
      canvasEyeRatio.current.height = imgRenderHeight;

      const ctxEyeRatio = canvasEyeRatio.current.getContext("2d");

      if (ctxEyeRatio) {
        drawLine(ctxEyeRatio, _line2, { color: "#FFFFFF", width: 1 });
        drawLine(ctxEyeRatio, _line3, { color: "#FFFFFF", width: 1 });
        drawLine(ctxEyeRatio, _line1Right, { color: "#FFFFFF", width: 1 });
        drawLine(ctxEyeRatio, _line2Right, { color: "#FFFFFF", width: 1 });

        drawDashLine(ctxEyeRatio, _line1, { color: "#BEBEBE" });
        drawDashLine(ctxEyeRatio, _line4, { color: "#BEBEBE" });
        drawDashLine(ctxEyeRatio, _line3Right, { color: "#BEBEBE" });
        drawDashLine(ctxEyeRatio, _line4Right, { color: "#BEBEBE" });
      }
    }
  }
};

// const drawGoldenRatio = (
//   dataFaceLandmark: any,
//   canvasGoldenRatio: any,
//   imgRealWidth: number,
//   imgRenderWidth: number,
//   imgRenderHeight: number
// ) => {
//   if (dataFaceLandmark) {
//     const imageScale = imgRealWidth / imgRenderWidth;

//     const leftEyebrowLeftCornerPint = {
//       x: dataFaceLandmark.left_eyebrow_left_corner.x,
//       y: dataFaceLandmark.left_eyebrow_left_corner.y
//     };
//     const leftEyebrowRightCornerPint = {
//       x: dataFaceLandmark.left_eyebrow_right_corner.x,
//       y: dataFaceLandmark.left_eyebrow_right_corner.y
//     };
//     const leftEyebrowUpperLeftQuarterPint = {
//       x: dataFaceLandmark.left_eyebrow_upper_left_quarter.x,
//       y: dataFaceLandmark.left_eyebrow_upper_left_quarter.y
//     };
//     const leftEyeLeftCornerPint = {
//       x: dataFaceLandmark.left_eye_left_corner.x,
//       y: dataFaceLandmark.left_eye_left_corner.y
//     };
//     const noseLeftPint = { x: dataFaceLandmark.nose_left.x, y: dataFaceLandmark.nose_left.y };

//     const _lineLeftDash1 = {
//       moveX: (leftEyebrowLeftCornerPint.x / imageScale),
//       moveY: (leftEyebrowLeftCornerPint.y / imageScale),
//       lineToX: (noseLeftPint.x / imageScale),
//       lineToY: (noseLeftPint.y / imageScale)
//     };

//     const _lineLeftDash2 = {
//       moveX: (leftEyebrowRightCornerPint.x / imageScale),
//       moveY: (leftEyebrowRightCornerPint.y / imageScale),
//       lineToX: (noseLeftPint.x / imageScale),
//       lineToY: (noseLeftPint.y / imageScale)
//     };

//     const _lineLeftDash3 = {
//       moveX: (leftEyebrowUpperLeftQuarterPint.x / imageScale),
//       moveY: (leftEyebrowUpperLeftQuarterPint.y / imageScale),
//       lineToX: (leftEyeLeftCornerPint.x / imageScale),
//       lineToY: (leftEyeLeftCornerPint.y / imageScale)
//     };

//     const rightEyebrowRightCornerPint = {
//       x: dataFaceLandmark.right_eyebrow_right_corner.x,
//       y: dataFaceLandmark.right_eyebrow_right_corner.y
//     };
//     const rightEyebrowLeftCornerPint = {
//       x: dataFaceLandmark.right_eyebrow_left_corner.x,
//       y: dataFaceLandmark.right_eyebrow_left_corner.y
//     };
//     const rightEyebrowUpperRightQuarterPint = {
//       x: dataFaceLandmark.right_eyebrow_upper_right_quarter.x,
//       y: dataFaceLandmark.right_eyebrow_upper_right_quarter.y
//     };
//     const rightEyeRightCornerPint = {
//       x: dataFaceLandmark.right_eye_right_corner.x,
//       y: dataFaceLandmark.right_eye_right_corner.y
//     };
//     const noseRightPint = { x: dataFaceLandmark.nose_right.x, y: dataFaceLandmark.nose_right.y };

//     const _lineRightDash4 = {
//       moveX: (rightEyebrowRightCornerPint.x / imageScale),
//       moveY: (rightEyebrowRightCornerPint.y / imageScale),
//       lineToX: (noseRightPint.x / imageScale),
//       lineToY: (noseRightPint.y / imageScale)
//     };

//     const _lineRightDash5 = {
//       moveX: (rightEyebrowLeftCornerPint.x / imageScale),
//       moveY: (rightEyebrowLeftCornerPint.y / imageScale),
//       lineToX: (noseRightPint.x / imageScale),
//       lineToY: (noseRightPint.y / imageScale)
//     };

//     const _lineRightDash6 = {
//       moveX: (rightEyebrowUpperRightQuarterPint.x / imageScale),
//       moveY: (rightEyebrowUpperRightQuarterPint.y / imageScale),
//       lineToX: (rightEyeRightCornerPint.x / imageScale),
//       lineToY: (rightEyeRightCornerPint.y / imageScale)
//     };

//     if (canvasGoldenRatio.current) {
//       canvasGoldenRatio.current.width = imgRenderWidth;
//       canvasGoldenRatio.current.height = imgRenderHeight;

//       const ctxGoldenRatio = canvasGoldenRatio.current.getContext("2d");

//       if (ctxGoldenRatio) {
//         drawDashLine(ctxGoldenRatio, _lineLeftDash1, { color: "#BEBEBE" });
//         drawDashLine(ctxGoldenRatio, _lineLeftDash2, { color: "#BEBEBE" });
//         drawDashLine(ctxGoldenRatio, _lineLeftDash3, { color: "#BEBEBE" });

//         drawDashLine(ctxGoldenRatio, _lineRightDash4, { color: "#BEBEBE" });
//         drawDashLine(ctxGoldenRatio, _lineRightDash5, { color: "#BEBEBE" });
//         drawDashLine(ctxGoldenRatio, _lineRightDash6, { color: "#BEBEBE" });
//       }
//     }
//   }
// };

const drawEyeBrowContour = (
  dataFaceDenseLandmark: any,
  canvasEyeBrowContour: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceDenseLandmark) {
    const imageScale = imgRealWidth / imgRenderWidth;

    const { left_eyebrow, right_eyebrow } = dataFaceDenseLandmark;

    if (canvasEyeBrowContour.current) {
      canvasEyeBrowContour.current.width = imgRenderWidth;
      canvasEyeBrowContour.current.height = imgRenderHeight;

      const ctxEyeBrowContour = canvasEyeBrowContour.current.getContext("2d");

      if (ctxEyeBrowContour) {
        drawFacePoint(
          convertToCoordinatesPoint(left_eyebrow),
          imageScale,
          ctxEyeBrowContour
        );
        drawFacePoint(
          convertToCoordinatesPoint(right_eyebrow),
          imageScale,
          ctxEyeBrowContour
        );
      }
    }
  }
};

const drawEyeBrowAngle = (
  dataFaceLandmark: any,
  canvasEyeBrowAngle: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceLandmark) {
    const imageScale = imgRealWidth / imgRenderWidth;

    const rightEyebrowRightCornerPint = {
      x: dataFaceLandmark.right_eyebrow_right_corner.x,
      y: dataFaceLandmark.right_eyebrow_right_corner.y
    };
    const rightEyebrowUpperRightQuarterPint = {
      x: dataFaceLandmark.right_eyebrow_upper_right_quarter.x,
      y: dataFaceLandmark.right_eyebrow_upper_right_quarter.y
    };
    const rightEyebrowLeftCornerPint = {
      x: dataFaceLandmark.right_eyebrow_left_corner.x,
      y: dataFaceLandmark.right_eyebrow_left_corner.y
    };

    const leftEyebrowLeftCornerPint = {
      x: dataFaceLandmark.left_eyebrow_left_corner.x,
      y: dataFaceLandmark.left_eyebrow_left_corner.y
    };
    const leftEyebrowUpperLeftQuarterPint = {
      x: dataFaceLandmark.left_eyebrow_upper_left_quarter.x,
      y: dataFaceLandmark.left_eyebrow_upper_left_quarter.y
    };
    const leftEyebrowRightCornerPint = {
      x: dataFaceLandmark.left_eyebrow_right_corner.x,
      y: dataFaceLandmark.left_eyebrow_right_corner.y
    };

    if (canvasEyeBrowAngle.current) {
      canvasEyeBrowAngle.current.width = imgRenderWidth;
      canvasEyeBrowAngle.current.height = imgRenderHeight;

      const ctxEyeBrowAngle = canvasEyeBrowAngle.current.getContext("2d");

      if (ctxEyeBrowAngle) {
        ctxEyeBrowAngle.beginPath();
        ctxEyeBrowAngle.moveTo(
          rightEyebrowRightCornerPint.x / imageScale,
          rightEyebrowRightCornerPint.y / imageScale
        );
        ctxEyeBrowAngle.lineTo(
          rightEyebrowUpperRightQuarterPint.x / imageScale,
          rightEyebrowUpperRightQuarterPint.y / imageScale
        );
        ctxEyeBrowAngle.lineTo(
          rightEyebrowLeftCornerPint.x / imageScale,
          rightEyebrowLeftCornerPint.y / imageScale
        );

        ctxEyeBrowAngle.moveTo(
          leftEyebrowLeftCornerPint.x / imageScale,
          leftEyebrowLeftCornerPint.y / imageScale
        );
        ctxEyeBrowAngle.lineTo(
          leftEyebrowUpperLeftQuarterPint.x / imageScale,
          leftEyebrowUpperLeftQuarterPint.y / imageScale
        );
        ctxEyeBrowAngle.lineTo(
          leftEyebrowRightCornerPint.x / imageScale,
          leftEyebrowRightCornerPint.y / imageScale
        );
        ctxEyeBrowAngle.strokeStyle = "#FFFFFF";
        ctxEyeBrowAngle.lineWidth = 1;
        ctxEyeBrowAngle.stroke();
      }
    }
  }
};

const drawEyeBrowFirstCorner = (
  dataFaceLandmark: any,
  canvasEyeBrowFirstCorner: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceLandmark) {
    const imageScale = imgRealWidth / imgRenderWidth;

    const leftEyebrowRightCornerPint = {
      x: dataFaceLandmark.left_eyebrow_right_corner.x,
      y: dataFaceLandmark.left_eyebrow_right_corner.y
    };

    const noseLeftPint = {
      x: dataFaceLandmark.nose_left.x,
      y: dataFaceLandmark.nose_left.y
    };

    const _lineLeftDash1 = {
      moveX: leftEyebrowRightCornerPint.x / imageScale,
      moveY: leftEyebrowRightCornerPint.y / imageScale,
      lineToX: noseLeftPint.x / imageScale,
      lineToY: noseLeftPint.y / imageScale
    };

    const rightEyebrowLeftCornerPint = {
      x: dataFaceLandmark.right_eyebrow_left_corner.x,
      y: dataFaceLandmark.right_eyebrow_left_corner.y
    };

    const noseRightPint = {
      x: dataFaceLandmark.nose_right.x,
      y: dataFaceLandmark.nose_right.y
    };

    const _lineRightDash1 = {
      moveX: rightEyebrowLeftCornerPint.x / imageScale,
      moveY: rightEyebrowLeftCornerPint.y / imageScale,
      lineToX: noseRightPint.x / imageScale,
      lineToY: noseRightPint.y / imageScale
    };

    if (canvasEyeBrowFirstCorner.current) {
      canvasEyeBrowFirstCorner.current.width = imgRenderWidth;
      canvasEyeBrowFirstCorner.current.height = imgRenderHeight;

      const ctxEyeBrowFirstCorner =
        canvasEyeBrowFirstCorner.current.getContext("2d");

      if (ctxEyeBrowFirstCorner) {
        drawCircle(
          ctxEyeBrowFirstCorner,
          leftEyebrowRightCornerPint,
          imageScale,
          { color: "#ffffff", radius: 3 }
        );
        drawDashLine(ctxEyeBrowFirstCorner, _lineLeftDash1, {
          color: "#BEBEBE"
        });

        drawCircle(
          ctxEyeBrowFirstCorner,
          rightEyebrowLeftCornerPint,
          imageScale,
          { color: "#ffffff", radius: 3 }
        );
        drawDashLine(ctxEyeBrowFirstCorner, _lineRightDash1, {
          color: "#BEBEBE"
        });
      }
    }
  }
};

const drawEyeBrowQuarter = (
  dataFaceLandmark: any,
  canvasEyeBrowQuarter: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceLandmark) {
    const imageScale = imgRealWidth / imgRenderWidth;

    const leftEyebrowUpperLeftQuarterPint = {
      x: dataFaceLandmark.left_eyebrow_upper_left_quarter.x,
      y: dataFaceLandmark.left_eyebrow_upper_left_quarter.y
    };

    const leftEyeLeftCornerPint = {
      x: dataFaceLandmark.left_eye_left_corner.x,
      y: dataFaceLandmark.left_eye_left_corner.y
    };
    const _lineLeftDash1 = {
      moveX: leftEyebrowUpperLeftQuarterPint.x / imageScale,
      moveY: leftEyebrowUpperLeftQuarterPint.y / imageScale,
      lineToX: leftEyeLeftCornerPint.x / imageScale,
      lineToY: leftEyeLeftCornerPint.y / imageScale
    };

    const rightEyebrowUpperRightQuarterPint = {
      x: dataFaceLandmark.right_eyebrow_upper_right_quarter.x,
      y: dataFaceLandmark.right_eyebrow_upper_right_quarter.y
    };

    const rightEyeRightCornerPint = {
      x: dataFaceLandmark.right_eye_right_corner.x,
      y: dataFaceLandmark.right_eye_right_corner.y
    };
    const _lineRightDash1 = {
      moveX: rightEyebrowUpperRightQuarterPint.x / imageScale,
      moveY: rightEyebrowUpperRightQuarterPint.y / imageScale,
      lineToX: rightEyeRightCornerPint.x / imageScale,
      lineToY: rightEyeRightCornerPint.y / imageScale
    };

    if (canvasEyeBrowQuarter.current) {
      canvasEyeBrowQuarter.current.width = imgRenderWidth;
      canvasEyeBrowQuarter.current.height = imgRenderHeight;

      const ctxEyeBrowQuarter = canvasEyeBrowQuarter.current.getContext("2d");

      if (ctxEyeBrowQuarter) {
        drawCircle(
          ctxEyeBrowQuarter,
          leftEyebrowUpperLeftQuarterPint,
          imageScale,
          { color: "#ffffff", radius: 3 }
        );
        drawDashLine(ctxEyeBrowQuarter, _lineLeftDash1, { color: "#BEBEBE" });

        drawCircle(
          ctxEyeBrowQuarter,
          rightEyebrowUpperRightQuarterPint,
          imageScale,
          {
            color: "#ffffff",
            radius: 3
          }
        );
        drawDashLine(ctxEyeBrowQuarter, _lineRightDash1, { color: "#BEBEBE" });
      }
    }
  }
};

const drawEyeBrowSecondCorner = (
  dataFaceLandmark: any,
  canvasEyeBrowSecondCorner: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceLandmark) {
    const imageScale = imgRealWidth / imgRenderWidth;

    const leftEyebrowLeftCornerPint = {
      x: dataFaceLandmark.left_eyebrow_left_corner.x,
      y: dataFaceLandmark.left_eyebrow_left_corner.y
    };

    const noseLeftPint = {
      x: dataFaceLandmark.nose_left.x,
      y: dataFaceLandmark.nose_left.y
    };

    const _lineLeftDash1 = {
      moveX: leftEyebrowLeftCornerPint.x / imageScale,
      moveY: leftEyebrowLeftCornerPint.y / imageScale,
      lineToX: noseLeftPint.x / imageScale,
      lineToY: noseLeftPint.y / imageScale
    };

    const rightEyebrowRightCornerPint = {
      x: dataFaceLandmark.right_eyebrow_right_corner.x,
      y: dataFaceLandmark.right_eyebrow_right_corner.y
    };

    const noseRightPint = {
      x: dataFaceLandmark.nose_right.x,
      y: dataFaceLandmark.nose_right.y
    };

    const _lineRightDash1 = {
      moveX: rightEyebrowRightCornerPint.x / imageScale,
      moveY: rightEyebrowRightCornerPint.y / imageScale,
      lineToX: noseRightPint.x / imageScale,
      lineToY: noseRightPint.y / imageScale
    };

    if (canvasEyeBrowSecondCorner.current) {
      canvasEyeBrowSecondCorner.current.width = imgRenderWidth;
      canvasEyeBrowSecondCorner.current.height = imgRenderHeight;

      const ctxEyeBrowSecondCorner =
        canvasEyeBrowSecondCorner.current.getContext("2d");

      if (ctxEyeBrowSecondCorner) {
        drawCircle(
          ctxEyeBrowSecondCorner,
          leftEyebrowLeftCornerPint,
          imageScale,
          { color: "#ffffff", radius: 3 }
        );
        drawDashLine(ctxEyeBrowSecondCorner, _lineLeftDash1, {
          color: "#BEBEBE"
        });

        drawCircle(
          ctxEyeBrowSecondCorner,
          rightEyebrowRightCornerPint,
          imageScale,
          { color: "#ffffff", radius: 3 }
        );
        drawDashLine(ctxEyeBrowSecondCorner, _lineRightDash1, {
          color: "#BEBEBE"
        });
      }
    }
  }
};

const drawNoseContour = (
  dataFaceDenseLandmark: any,
  canvasNoseContour: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceDenseLandmark) {
    const imageScale = imgRealWidth / imgRenderWidth;

    const { nose } = dataFaceDenseLandmark;

    if (canvasNoseContour.current) {
      canvasNoseContour.current.width = imgRenderWidth;
      canvasNoseContour.current.height = imgRenderHeight;

      const ctxNoseContour = canvasNoseContour.current.getContext("2d");

      if (ctxNoseContour) {
        drawFacePoint(
          convertToCoordinatesPoint(nose),
          imageScale,
          ctxNoseContour
        );
      }
    }
  }
};

const drawNoseRation = (
  dataFaceLandmark: any,
  canvasNoseRatio: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceLandmark) {
    const imageScale = imgRealWidth / imgRenderWidth;

    const {
      nose_left,
      nose_right,
      contour_chin,
      nose_tip,
      left_eye_left_corner,
      right_eye_right_corner,
      mouth_upper_lip_left_contour2,
      mouth_upper_lip_right_contour2
    } = dataFaceLandmark;

    const serviceResults = noseRatioService({
      nose_left,
      nose_right,
      contour_chin,
      nose_tip,
      left_eye_left_corner,
      right_eye_right_corner,
      mouth_upper_lip_left_contour2,
      mouth_upper_lip_right_contour2
    });

    const { topLeftPoint, topRightPoint, bottomLeftPoint, bottomRightPoint } =
      serviceResults;

    const _verticalLine1 = {
      moveX: topLeftPoint.x / imageScale,
      moveY: topLeftPoint.y / imageScale,
      lineToX: bottomLeftPoint.x / imageScale,
      lineToY: bottomLeftPoint.y / imageScale
    };
    const _verticalLine2 = {
      moveX: topRightPoint.x / imageScale,
      moveY: topRightPoint.y / imageScale,
      lineToX: bottomRightPoint.x / imageScale,
      lineToY: bottomRightPoint.y / imageScale
    };
    if (canvasNoseRatio.current) {
      canvasNoseRatio.current.width = imgRenderWidth;
      canvasNoseRatio.current.height = imgRenderHeight;

      const ctxNoseRatio = canvasNoseRatio.current.getContext("2d");

      if (ctxNoseRatio) {
        drawLine(ctxNoseRatio, _verticalLine1, { width: 1, color: "#FFFFFF" });
        drawLine(ctxNoseRatio, _verticalLine2, { width: 1, color: "#FFFFFF" });
      }
    }
  }
};

const drawMouthContour = (
  dataFaceLandmark: any,
  dataFaceDenseLandmark: any,
  canvasMouthContour: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceLandmark && dataFaceDenseLandmark) {
    const imageScale = imgRealWidth / imgRenderWidth;

    const { mouth } = dataFaceDenseLandmark;

    const {
      left_eye_top,
      right_eye_top,
      mouth_lower_lip_bottom,
      mouth_upper_lip_bottom,
      mouth_upper_lip_left_contour1
    } = dataFaceLandmark;

    const x1 = left_eye_top.x;
    const y1 = left_eye_top.y;
    const x2 = right_eye_top.x;
    const y2 = right_eye_top.y;

    const mouthBottomPint_D = calculateHeadTiltedPoint(
      x1,
      y1,
      x2,
      y2,
      mouth_lower_lip_bottom.x,
      mouth_lower_lip_bottom.y
    );
    const mouthUpperLipBottomPint_D = calculateHeadTiltedPoint(
      x1,
      y1,
      x2,
      y2,
      mouth_upper_lip_bottom.x,
      mouth_upper_lip_bottom.y
    );

    const mouthUpperLipLeftContour1Pint_D = calculateHeadTiltedPoint(
      x1,
      y1,
      x2,
      y2,
      mouth_upper_lip_left_contour1.x,
      mouth_upper_lip_left_contour1.y
    );

    const _horizontalLine1 = {
      moveX:
        mouthBottomPint_D.x / imageScale -
        (mouth_lower_lip_bottom.x / imageScale -
          mouthBottomPint_D.x / imageScale) *
          100,
      moveY:
        mouthBottomPint_D.y / imageScale -
        (mouth_lower_lip_bottom.y / imageScale -
          mouthBottomPint_D.y / imageScale) *
          100,
      lineToX:
        mouth_lower_lip_bottom.x / imageScale -
        (mouthBottomPint_D.x / imageScale -
          mouth_lower_lip_bottom.x / imageScale) *
          100,
      lineToY:
        mouth_lower_lip_bottom.y / imageScale -
        (mouthBottomPint_D.y / imageScale -
          mouth_lower_lip_bottom.y / imageScale) *
          100
    };

    const _horizontalLine2 = {
      moveX:
        mouthUpperLipBottomPint_D.x / imageScale -
        (mouth_upper_lip_bottom.x / imageScale -
          mouthUpperLipBottomPint_D.x / imageScale) *
          100,
      moveY:
        mouthUpperLipBottomPint_D.y / imageScale -
        (mouth_upper_lip_bottom.y / imageScale -
          mouthUpperLipBottomPint_D.y / imageScale) *
          100,
      lineToX:
        mouth_upper_lip_bottom.x / imageScale -
        (mouthUpperLipBottomPint_D.x / imageScale -
          mouth_upper_lip_bottom.x / imageScale) *
          100,
      lineToY:
        mouth_upper_lip_bottom.y / imageScale -
        (mouthUpperLipBottomPint_D.y / imageScale -
          mouth_upper_lip_bottom.y / imageScale) *
          100
    };

    const _horizontalLine3 = {
      moveX:
        mouthUpperLipLeftContour1Pint_D.x / imageScale -
        (mouth_upper_lip_left_contour1.x / imageScale -
          mouthUpperLipLeftContour1Pint_D.x / imageScale) *
          -0.15,
      moveY:
        mouthUpperLipLeftContour1Pint_D.y / imageScale -
        (mouth_upper_lip_left_contour1.y / imageScale -
          mouthUpperLipLeftContour1Pint_D.y / imageScale) *
          -0.15,
      lineToX:
        mouth_upper_lip_left_contour1.x / imageScale -
        (mouthUpperLipLeftContour1Pint_D.x / imageScale -
          mouth_upper_lip_left_contour1.x / imageScale) *
          0.55,
      lineToY:
        mouth_upper_lip_left_contour1.y / imageScale -
        (mouthUpperLipLeftContour1Pint_D.y / imageScale -
          mouth_upper_lip_left_contour1.y / imageScale) *
          0.55
    };

    const noseContourLowerMiddlePint = {
      x: dataFaceLandmark.nose_contour_lower_middle.x,
      y: dataFaceLandmark.nose_contour_lower_middle.y
    };
    const contourChinPint = {
      x: dataFaceLandmark.contour_chin.x,
      y: dataFaceLandmark.contour_chin.y
    };

    const _verticalLine1 = {
      moveX: noseContourLowerMiddlePint.x / imageScale,
      moveY: noseContourLowerMiddlePint.y / imageScale,
      lineToX: contourChinPint.x / imageScale,
      lineToY: contourChinPint.y / imageScale
    };

    if (canvasMouthContour.current) {
      canvasMouthContour.current.width = imgRenderWidth;
      canvasMouthContour.current.height = imgRenderHeight;

      const ctxMouthContour = canvasMouthContour.current.getContext("2d");

      if (ctxMouthContour) {
        drawFacePoint(
          convertToCoordinatesPoint(mouth),
          imageScale,
          ctxMouthContour
        );

        drawDashLine(ctxMouthContour, _horizontalLine1, { color: "#BEBEBE" });
        drawDashLine(ctxMouthContour, _horizontalLine2, { color: "#BEBEBE" });
        drawDashLine(ctxMouthContour, _horizontalLine3, { color: "#BEBEBE" });
        drawDashLine(ctxMouthContour, _verticalLine1, { color: "#BEBEBE" });
      }
    }
  }
};

const drawMouthAngle = (
  dataFaceLandmark: any,
  canvasMouthAngle: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceLandmark) {
    const imageScale = imgRealWidth / imgRenderWidth;

    const mouthRightCornerPint = {
      x: dataFaceLandmark.mouth_right_corner.x,
      y: dataFaceLandmark.mouth_right_corner.y
    };
    const mouthLowerLipRightContour3Pint = {
      x: dataFaceLandmark.mouth_lower_lip_right_contour3.x,
      y: dataFaceLandmark.mouth_lower_lip_right_contour3.y
    };

    const _mouthAngleLine1 = {
      moveX: mouthRightCornerPint.x / imageScale,
      moveY: mouthRightCornerPint.y / imageScale,
      lineToX: mouthLowerLipRightContour3Pint.x / imageScale,
      lineToY: mouthLowerLipRightContour3Pint.y / imageScale
    };

    const mouthLeftCornerPint = {
      x: dataFaceLandmark.mouth_left_corner.x,
      y: dataFaceLandmark.mouth_left_corner.y
    };
    const mouthLowerLipLeftContour3Pint = {
      x: dataFaceLandmark.mouth_lower_lip_left_contour3.x,
      y: dataFaceLandmark.mouth_lower_lip_left_contour3.y
    };

    const _mouthAngleLine2 = {
      moveX: mouthLeftCornerPint.x / imageScale,
      moveY: mouthLeftCornerPint.y / imageScale,
      lineToX: mouthLowerLipLeftContour3Pint.x / imageScale,
      lineToY: mouthLowerLipLeftContour3Pint.y / imageScale
    };

    if (canvasMouthAngle.current) {
      canvasMouthAngle.current.width = imgRenderWidth;
      canvasMouthAngle.current.height = imgRenderHeight;

      const ctxMouthAngle = canvasMouthAngle.current.getContext("2d");

      if (ctxMouthAngle) {
        drawLine(ctxMouthAngle, _mouthAngleLine1, {
          color: "#FFFFFF",
          width: 1
        });
        drawLine(ctxMouthAngle, _mouthAngleLine2, {
          color: "#FFFFFF",
          width: 1
        });
      }
    }
  }
};

const drawMouthToNose = (
  dataFaceLandmark: any,
  canvasMouthToNose: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceLandmark) {
    const imageScale = imgRealWidth / imgRenderWidth;

    const mouthRightCornerPint = {
      x: dataFaceLandmark.mouth_right_corner.x,
      y: dataFaceLandmark.mouth_right_corner.y
    };
    // const noseContourLowerMiddlePint = {
    //   x: dataFaceLandmark.nose_contour_lower_middle.x,
    //   y: dataFaceLandmark.nose_contour_lower_middle.y
    // };
    // const mouthUpperLipBottomPint = {
    //   x: dataFaceLandmark.mouth_upper_lip_top.x,
    //   y: dataFaceLandmark.mouth_upper_lip_top.y
    // };
    const mouthLowerLipBottomPint = {
      x: dataFaceLandmark.mouth_lower_lip_bottom.x,
      y: dataFaceLandmark.mouth_lower_lip_bottom.y
    };
    // const contourChinPint = {
    //   x: dataFaceLandmark.contour_chin.x,
    //   y: dataFaceLandmark.contour_chin.y
    // };

    // const _ctxMouthToNoseLine1 = {
    //   moveX: (mouthRightCornerPint.x),
    //   moveY: (noseContourLowerMiddlePint.y),
    //   lineToX: (mouthRightCornerPint.x),
    //   lineToY: (mouthRightCornerPint.y)
    // };

    // const _ctxMouthToChinLine2 = {
    //   moveX: (mouthRightCornerPint.x),
    //   moveY: (mouthLowerLipBottomPint.y),
    //   lineToX: (mouthRightCornerPint.x),
    //   lineToY: (contourChinPint.y)
    // };

    const _ctxMouthToChinLine3 = {
      moveX: mouthRightCornerPint.x / imageScale,
      moveY: mouthRightCornerPint.y / imageScale,
      lineToX: mouthRightCornerPint.x / imageScale + 8,
      lineToY: mouthRightCornerPint.y / imageScale
    };

    const _ctxMouthToChinLine4 = {
      moveX: mouthRightCornerPint.x / imageScale,
      moveY: mouthRightCornerPint.y / imageScale,
      lineToX: mouthRightCornerPint.x / imageScale,
      lineToY: mouthLowerLipBottomPint.y / imageScale
    };

    const {
      nose_contour_lower_middle: A,
      contour_chin: B,
      mouth_right_corner: C
    } = dataFaceLandmark;
    const D = calculateHeadTiltedPoint(A.x, A.y, B.x, B.y, C.x, C.y);
    const Z = {
      x: D.x - B.x + A.x,
      y: D.y - B.y + A.y
    };

    if (canvasMouthToNose.current) {
      canvasMouthToNose.current.width = imgRenderWidth;
      canvasMouthToNose.current.height = imgRenderHeight;

      const ctxMouthToNose = canvasMouthToNose.current.getContext("2d");

      if (ctxMouthToNose) {
        drawLine(
          ctxMouthToNose,
          {
            moveX: D.x / imageScale,
            moveY: D.y / imageScale,
            lineToX: C.x / imageScale,
            lineToY: C.y / imageScale
          },
          { color: "#FFFFFF", width: 1 }
        );
        drawLine(
          ctxMouthToNose,
          {
            moveX: D.x / imageScale,
            moveY: D.y / imageScale,
            lineToX: Z.x / imageScale,
            lineToY: Z.y / imageScale
          },
          { color: "#FFFFFF", width: 1 }
        );
        //drawLine(ctxMouthToNose, _ctxMouthToNoseLine1, { color: "#FFFFFF", width: 2 });
        //drawLine(ctxMouthToNose, _ctxMouthToChinLine2, { color: "#FFFFFF", width: 2 });
        drawLine(ctxMouthToNose, _ctxMouthToChinLine3, {
          color: "#FFFFFF",
          width: 1
        });
        drawLine(ctxMouthToNose, _ctxMouthToChinLine4, {
          color: "#FFFFFF",
          width: 1
        });
      }
    }
  }
};

const drawMouthToChin = (
  dataFaceLandmark: any,
  canvasMouthToChin: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceLandmark) {
    const imageScale = imgRealWidth / imgRenderWidth;

    // const mouthLeftCornerPint = {
    //   x: dataFaceLandmark.mouth_left_corner.x,
    //   y: dataFaceLandmark.mouth_left_corner.y
    // };
    // const noseContourLowerMiddlePint = {
    //   x: dataFaceLandmark.nose_contour_lower_middle.x,
    //   y: dataFaceLandmark.nose_contour_lower_middle.y
    // };
    // const mouthLowerLipBottomPint = {
    //   x: dataFaceLandmark.mouth_lower_lip_bottom.x,
    //   y: dataFaceLandmark.mouth_lower_lip_bottom.y
    // };
    // const contourChinPint = {
    //   x: dataFaceLandmark.contour_chin.x,
    //   y: dataFaceLandmark.contour_chin.y
    // };

    // const _ctxMouthToChinLine1 = {
    //   moveX: (mouthLeftCornerPint.x),
    //   moveY: (noseContourLowerMiddlePint.y),
    //   lineToX: (mouthLeftCornerPint.x),
    //   lineToY: (mouthLowerLipBottomPint.y)
    // };

    // const _ctxMouthToChinLine2 = {
    //   moveX: (mouthLeftCornerPint.x),
    //   moveY: (mouthLowerLipBottomPint.y),
    //   lineToX: (mouthLeftCornerPint.x),
    //   lineToY: (contourChinPint.y)
    // };

    // const _ctxMouthToChinLine3 = {
    //   moveX: (mouthLeftCornerPint.x),
    //   moveY: (mouthLowerLipBottomPint.y),
    //   lineToX: (mouthLeftCornerPint.x - 8),
    //   lineToY: (mouthLowerLipBottomPint.y)
    // };
    const {
      nose_contour_lower_middle: A,
      contour_chin: B,
      mouth_left_corner: C
    } = dataFaceLandmark;
    const D = calculateHeadTiltedPoint(A.x, A.y, B.x, B.y, C.x, C.y);
    const Z = {
      x: D.x - B.x + A.x,
      y: D.y - B.y + A.y
    };

    const Y = {
      x: (D.x + Z.x) / 2,
      y: (D.y + Z.y) / 2
    };

    if (canvasMouthToChin.current) {
      canvasMouthToChin.current.width = imgRenderWidth;
      canvasMouthToChin.current.height = imgRenderHeight;

      const ctxMouthToChin = canvasMouthToChin.current.getContext("2d");

      if (ctxMouthToChin) {
        drawLine(
          ctxMouthToChin,
          {
            moveX: D.x / imageScale,
            moveY: D.y / imageScale,
            lineToX: C.x / imageScale,
            lineToY: C.y / imageScale
          },
          { color: "#FFFFFF", width: 1 }
        );
        drawLine(
          ctxMouthToChin,
          {
            moveX: D.x / imageScale,
            moveY: D.y / imageScale,
            lineToX: Z.x / imageScale,
            lineToY: Z.y / imageScale
          },
          { color: "#FFFFFF", width: 1 }
        );
        drawLine(
          ctxMouthToChin,
          {
            moveX: Y.x / imageScale,
            moveY: Y.y / imageScale,
            lineToX: Y.x / imageScale - 8,
            lineToY: Y.y / imageScale
          },
          { color: "#FFFFFF", width: 1 }
        );
        //drawLine(ctxMouthToChin, _ctxMouthToChinLine1, { color: "#FFFFFF", width: 2 });
        //drawLine(ctxMouthToChin, _ctxMouthToChinLine2, { color: "#FFFFFF", width: 2 });
        //drawLine(ctxMouthToChin, _ctxMouthToChinLine3, { color: "#FFFFFF", width: 2 });
      }
    }
  }
};

const drawAcneArea = (
  acnePoint: any,
  canvasAcneArea: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (acnePoint && imgRealWidth > 0 && imgRenderWidth > 0) {
    const imageScale = imgRealWidth / imgRenderWidth;

    if (canvasAcneArea.current) {
      canvasAcneArea.current.width = imgRenderWidth;
      canvasAcneArea.current.height = imgRenderHeight;

      const ctxAcneArea = canvasAcneArea.current.getContext("2d");

      if (ctxAcneArea) {
        drawFacePoint(acnePoint, imageScale, ctxAcneArea, { color: "#FF42EC" });
      }
    }
  }
};

const drawPoresArea = (
  poresPoint: any,
  canvasPoresArea: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (poresPoint && imgRealWidth > 0 && imgRenderWidth > 0) {
    const imageScale = imgRealWidth / imgRenderWidth;

    if (canvasPoresArea.current) {
      canvasPoresArea.current.width = imgRenderWidth;
      canvasPoresArea.current.height = imgRenderHeight;

      const ctxPoresArea = canvasPoresArea.current.getContext("2d");

      if (ctxPoresArea) {
        drawFacePoint(poresPoint, imageScale, ctxPoresArea, {
          color: "#FF42EC"
        });
      }
    }
  }
};

const drawEline = (
  dataFaceLandmark: any,
  sideFaceAttributes: any,
  canvasEline: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceLandmark && sideFaceAttributes) {
    const imageScale = imgRealWidth / imgRenderWidth;

    const nosePint = {
      x:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? dataFaceLandmark.nose_left.x
          : dataFaceLandmark.nose_right.x,
      y:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? dataFaceLandmark.nose_left.y
          : dataFaceLandmark.nose_right.y
    };

    const contourChinPint = {
      x: dataFaceLandmark.contour_chin.x,
      y: dataFaceLandmark.contour_chin.y
    };

    const newTopPointX = nosePint.x - (contourChinPint.x - nosePint.x) * 0.75;
    const newTopPointY = nosePint.y - (contourChinPint.y - nosePint.y) * 0.75;

    const newUnderPointX =
      contourChinPint.x - (nosePint.x - contourChinPint.x) * 0.75;
    const newUnderPointY =
      contourChinPint.y - (nosePint.y - contourChinPint.y) * 0.75;

    if (canvasEline.current) {
      canvasEline.current.width = imgRenderWidth;
      canvasEline.current.height = imgRenderHeight;

      const ctxEline = canvasEline.current.getContext("2d");

      if (ctxEline) {
        ctxEline.beginPath();
        ctxEline.moveTo(newTopPointX / imageScale, newTopPointY / imageScale);
        ctxEline.lineTo(nosePint.x / imageScale, nosePint.y / imageScale);
        ctxEline.lineTo(
          contourChinPint.x / imageScale,
          contourChinPint.y / imageScale
        );
        ctxEline.lineTo(
          newUnderPointX / imageScale,
          newUnderPointY / imageScale
        );
        ctxEline.strokeStyle = "#33B294";
        ctxEline.lineWidth = 1;
        ctxEline.stroke();
      }
    }
  }
};

const drawNoseAngle = (
  dataFaceLandmark: any,
  sideFaceAttributes: any,
  canvasNoseAngle: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceLandmark && sideFaceAttributes) {
    const imageScale = imgRealWidth / imgRenderWidth;

    const nosePint = {
      x:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? dataFaceLandmark.nose_left.x
          : dataFaceLandmark.nose_right.x,
      y:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? dataFaceLandmark.nose_left.y
          : dataFaceLandmark.nose_right.y
    };

    const noseContour3Pint = {
      x:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? dataFaceLandmark.nose_contour_right3.x
          : dataFaceLandmark.nose_contour_left3.x,
      y:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? dataFaceLandmark.nose_contour_right3.y
          : dataFaceLandmark.nose_contour_left3.y
    };

    const contour5Pint = {
      x:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? dataFaceLandmark.contour_left5.x
          : dataFaceLandmark.contour_right5.x,
      y:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? dataFaceLandmark.contour_left5.y
          : dataFaceLandmark.contour_right5.y
    };

    if (canvasNoseAngle.current) {
      canvasNoseAngle.current.width = imgRenderWidth;
      canvasNoseAngle.current.height = imgRenderHeight;

      const ctxNoseAngle = canvasNoseAngle.current.getContext("2d");
      if (ctxNoseAngle) {
        ctxNoseAngle.beginPath();
        ctxNoseAngle.moveTo(nosePint.x / imageScale, nosePint.y / imageScale);
        ctxNoseAngle.lineTo(
          noseContour3Pint.x / imageScale,
          noseContour3Pint.y / imageScale
        );
        ctxNoseAngle.lineTo(
          contour5Pint.x / imageScale,
          contour5Pint.y / imageScale
        );
        ctxNoseAngle.strokeStyle = "#33B294";
        ctxNoseAngle.lineWidth = 1;
        ctxNoseAngle.stroke();
      }
    }
  }
};

const drawSubmandibularAngle = (
  dataFaceLandmark: any,
  sideFaceAttributes: any,
  canvasSubmandibularAngle: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceLandmark && sideFaceAttributes) {
    const imageScale = imgRealWidth / imgRenderWidth;

    const nosePint = {
      x:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? dataFaceLandmark.nose_left.x
          : dataFaceLandmark.nose_right.x,
      y:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? dataFaceLandmark.nose_left.y
          : dataFaceLandmark.nose_right.y
    };

    const contourChinPint = {
      x: dataFaceLandmark.contour_chin.x,
      y: dataFaceLandmark.contour_chin.y
    };

    const contour8Pint = {
      x:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? dataFaceLandmark.contour_right8.x
          : dataFaceLandmark.contour_left8.x,
      y:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? dataFaceLandmark.contour_right8.y
          : dataFaceLandmark.contour_left8.y
    };

    if (canvasSubmandibularAngle.current) {
      canvasSubmandibularAngle.current.width = imgRenderWidth;
      canvasSubmandibularAngle.current.height = imgRenderHeight;

      const ctxSubmandibularAngle =
        canvasSubmandibularAngle.current.getContext("2d");
      if (ctxSubmandibularAngle) {
        ctxSubmandibularAngle.beginPath();
        ctxSubmandibularAngle.moveTo(
          nosePint.x / imageScale,
          nosePint.y / imageScale
        );
        ctxSubmandibularAngle.lineTo(
          contourChinPint.x / imageScale,
          contourChinPint.y / imageScale
        );
        ctxSubmandibularAngle.lineTo(
          contour8Pint.x / imageScale,
          contour8Pint.y / imageScale
        );
        ctxSubmandibularAngle.strokeStyle = "#33B294";
        ctxSubmandibularAngle.lineWidth = 1;
        ctxSubmandibularAngle.stroke();
      }
    }
  }
};

const drawSideDashLine = (
  dataFaceLandmark: any,
  sideFaceAttributes: any,
  canvasSideDashLine: any,
  imgRealWidth: number,
  imgRenderWidth: number,
  imgRenderHeight: number
) => {
  if (dataFaceLandmark && sideFaceAttributes) {
    const imageScale = imgRealWidth / imgRenderWidth;

    const nosePint = {
      x:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? dataFaceLandmark.nose_left.x
          : dataFaceLandmark.nose_right.x,
      y:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? dataFaceLandmark.nose_left.y
          : dataFaceLandmark.nose_right.y
    };

    const contour9Pint = {
      x:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? dataFaceLandmark.contour_left9.x
          : dataFaceLandmark.contour_right9.x,
      y:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? dataFaceLandmark.contour_left9.y
          : dataFaceLandmark.contour_right9.y
    };

    const noseContourLowerMiddlePint = {
      x: dataFaceLandmark.nose_contour_lower_middle.x,
      y: dataFaceLandmark.nose_contour_lower_middle.y
    };

    const _verticalLine1 = {
      moveX: nosePint.x / imageScale,
      moveY: 0,
      lineToX: nosePint.x / imageScale,
      lineToY: imgRenderHeight
    };

    const _verticalLine2 = {
      moveX:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? noseContourLowerMiddlePint.x / imageScale - 5
          : noseContourLowerMiddlePint.x / imageScale + 5,
      moveY: 0,
      lineToX:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? noseContourLowerMiddlePint.x / imageScale - 5
          : noseContourLowerMiddlePint.x / imageScale + 5,
      lineToY: imgRenderHeight
    };

    const _verticalLine3 = {
      moveX:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? contour9Pint.x / imageScale - 5
          : contour9Pint.x / imageScale + 5,
      moveY: 0,
      lineToX:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? contour9Pint.x / imageScale - 5
          : contour9Pint.x / imageScale + 5,
      lineToY: imgRenderHeight
    };

    const _verticalLine4 = {
      moveX:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? contour9Pint.x / imageScale - 9
          : contour9Pint.x / imageScale + 9,
      moveY: 0,
      lineToX:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? contour9Pint.x / imageScale - 9
          : contour9Pint.x / imageScale + 9,
      lineToY: imgRenderHeight
    };

    const contour5Pint = {
      x:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? dataFaceLandmark.contour_left5.x
          : dataFaceLandmark.contour_right5.x,
      y:
        sideFaceAttributes?.headpose?.yaw_angle > 0
          ? dataFaceLandmark.contour_left5.y
          : dataFaceLandmark.contour_right5.y
    };

    const contourChinPint = {
      x: dataFaceLandmark.contour_chin.x,
      y: dataFaceLandmark.contour_chin.y
    };

    const _horizontalLine1 = {
      moveX: 0,
      moveY: nosePint.y / imageScale,
      lineToX: imgRenderWidth,
      lineToY: nosePint.y / imageScale
    };

    const _horizontalLine2 = {
      moveX: 0,
      moveY: nosePint.y / imageScale + 5,
      lineToX: imgRenderWidth,
      lineToY: nosePint.y / imageScale + 5
    };

    const _horizontalLine3 = {
      moveX: 0,
      moveY: contour5Pint.y / imageScale,
      lineToX: imgRenderWidth,
      lineToY: contour5Pint.y / imageScale
    };

    const _horizontalLine4 = {
      moveX: 0,
      moveY: contourChinPint.y / imageScale,
      lineToX: imgRenderWidth,
      lineToY: contourChinPint.y / imageScale
    };

    if (canvasSideDashLine.current) {
      canvasSideDashLine.current.width = imgRenderWidth;
      canvasSideDashLine.current.height = imgRenderHeight;

      const ctxSideDashLine = canvasSideDashLine.current.getContext("2d");
      if (ctxSideDashLine) {
        drawDashLine(ctxSideDashLine, _verticalLine1, { color: "#BEBEBE" });
        drawDashLine(ctxSideDashLine, _verticalLine2, { color: "#BEBEBE" });
        drawDashLine(ctxSideDashLine, _verticalLine3, { color: "#BEBEBE" });
        drawDashLine(ctxSideDashLine, _verticalLine4, { color: "#BEBEBE" });

        drawDashLine(ctxSideDashLine, _horizontalLine1, { color: "#BEBEBE" });
        drawDashLine(ctxSideDashLine, _horizontalLine2, { color: "#BEBEBE" });
        drawDashLine(ctxSideDashLine, _horizontalLine3, { color: "#BEBEBE" });
        drawDashLine(ctxSideDashLine, _horizontalLine4, { color: "#BEBEBE" });
      }
    }
  }
};

export {
  drawHorizontalBalanceLine,
  drawVerticalBalanceLine,
  drawJunctionLine,
  drawFacialLaterality,
  drawFaceRatio,
  drawTriangleLine,
  drawTrapezoidalArea,
  drawFaceContour,
  drawEyeContour,
  drawEyeAngle,
  drawEyeRatio,
  // drawGoldenRatio,
  drawEyeBrowContour,
  drawEyeBrowAngle,
  drawEyeBrowFirstCorner,
  drawEyeBrowQuarter,
  drawEyeBrowSecondCorner,
  drawNoseContour,
  drawNoseRation,
  drawMouthContour,
  drawMouthAngle,
  drawMouthToNose,
  drawMouthToChin,
  drawAcneArea,
  drawPoresArea,
  drawEline,
  drawNoseAngle,
  drawSubmandibularAngle,
  drawSideDashLine
};
