import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { postApi, putApi } from "api/common/api";
import { API_URL } from "api/common/path";
import { useCompanyDetail, usePaymentGetQuery } from "api/usePayment";
import IconTinyArrowDown from "assets/icons/ic-tiny-arrow-down-black.svg";
import {
  LOCAL_STORAGE_KEY,
  PLAN_LIST,
  PLAN_TYPE,
  PRICE
} from "constants/common";
import { ROUTE } from "constants/paths";
import { useUser } from "context/UserContext";
import "dayjs/locale/ja";
import { NotifyTypeEnum } from "enums/notify";
import MenuHeader from "layouts/MainLayout/MenuHeader";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { formatCurrencyJa } from "utils/common";
import { notify } from "utils/notify";

import Container from "components/Container";
import FormSelectFieldCustom from "components/Form/FormSelectFieldCustom";
import FormTextField from "components/Form/FormTextField";
import Loading from "components/Loading";

const translateStripeError = (errorCode: string) => {
  const errorMessages: any = {
    incomplete_number: "カード番号が入力されていません。",
    invalid_number: "無効なカード番号です。",
    incomplete_expiry: "有効期限が入力されていません。",
    invalid_expiry_month: "有効期限の月が無効です。",
    invalid_expiry_year: "有効期限の年が無効です。",
    incomplete_cvc: "CVCコードが入力されていません。",
    invalid_cvc: "無効なCVCコードです。",
    expired_card: "カードの有効期限が切れています。",
    incorrect_number: "カード番号が正しくありません。",
    card_declined: "カードが拒否されました。",
    processing_error: "カードの処理中にエラーが発生しました。"
  };

  return errorMessages[errorCode] || "不明なエラーが発生しました。";
};

const PaymentAdd: React.FC = () => {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<{
    date: any;
    plan: string;
    total_account: number | null;
    card_number: string;
    cvc: string;
    card_name: string;
    subscription_id?: string;
  }>({
    defaultValues: {
      date: null,
      plan: "standard",
      total_account: null,
      card_number: "",
      cvc: "",
      card_name: "",
      subscription_id: ""
    }
    // resolver: yupResolver(profileNewPasswordSchema)
  });
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const paymentQuery = usePaymentGetQuery();
  const { data: paymentData } = useQuery<any>(
    ["get_payment"],
    () => queryClient.getQueryData(["get_payment"]),
    {
      onError: (err) => {
        return null;
      },
      retry: true
    }
  );

  const { data: isEditPayment } = useQuery(
    ["isEditPayment"],
    () => !!queryClient.getQueryData(["isEditPayment"]),
    {
      onError: (err) => {
        return false;
      },
      retry: false,
      initialData: false
    }
  );

  const stripe = useStripe();
  const elements = useElements();
  const { userInfo } = useUser();

  const [loading, setLoading] = useState<boolean>(false);
  const [cardError, setCardError] = useState<any>(null);
  const [expiryError, setExpiryError] = useState<any>(null);
  const [cvcError, setCvcError] = useState<any>(null);
  const [planWatch, setPlanWatch] = React.useState<string | null>("standard");
  const [totalWatch, setTotalWatch] = React.useState<number | null>(0);
  const [isCardNumberComplete, setIsCardNumberComplete] = useState(false);

  const { data: company, isLoading } = useCompanyDetail();

  const handleCardNumberChange = (event: any) => {
    setIsCardNumberComplete(event.complete);
    setCardError(event.error ? translateStripeError(event.error.code) : null);
  };

  const handleExpiryChange = (event: any) => {
    setExpiryError(event.error ? translateStripeError(event.error.code) : null);
  };

  const handleCvcChange = (event: any) => {
    setCvcError(event.error ? translateStripeError(event.error.code) : null);
  };

  const onFormSubmit = (event: any) => {
    event.preventDefault();
    handleSubmit(async (data) => {
      setLoading(true);
      if (isEditPayment) {
        let payment_method_id: any = null;
        if (isCardNumberComplete) {
          if (!stripe || !elements) {
            notify("Stripe is not loaded", NotifyTypeEnum.ERROR);
            setLoading(false);
            return;
          }
          const cardNumberElement = elements.getElement(CardNumberElement);
          if (!cardNumberElement) {
            notify("CardElement not found", NotifyTypeEnum.ERROR);
            return;
          }
          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardNumberElement
          });
          payment_method_id = paymentMethod?.id;
          if (error) {
            setLoading(false);
            return;
          }
        }

        try {
          const result: any = await putApi(
            API_URL?.PAYMENT?.EDIT,
            payment_method_id
              ? {
                  payment_method_id: payment_method_id,
                  plan_name: data?.plan,
                  staffs_amount: Number(data?.total_account),
                  subscription_id: data?.subscription_id,
                  stripe_customer_name: data?.card_name
                }
              : {
                  plan_name: data?.plan,
                  staffs_amount: Number(data?.total_account),
                  subscription_id: data?.subscription_id,
                  stripe_customer_name: data?.card_name
                }
          );
          notify("お支払いが完了しました", NotifyTypeEnum.SUCCESS);
          if (result) {
            queryClient.setQueryData(["isEditPayment"], false);
            localStorage.setItem(LOCAL_STORAGE_KEY.PAYMENT_STATUS, "paid");
          }
        } catch (err) {
          notify("error", NotifyTypeEnum.ERROR);
        }
      } else {
        if (!stripe || !elements) {
          notify("Stripe is not loaded", NotifyTypeEnum.ERROR);
          setLoading(false);
          return;
        }
        const cardNumberElement = elements.getElement(CardNumberElement);
        if (!cardNumberElement) {
          notify("CardElement not found", NotifyTypeEnum.ERROR);
          return;
        }
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardNumberElement
        });
        if (error) {
          setLoading(false);
          return;
        }
        try {
          await postApi(API_URL?.PAYMENT?.ADD, {
            plan_name: data?.plan,
            staffs_amount: Number(data?.total_account),
            payment_method_id: paymentMethod.id,
            stripe_customer_name: data?.card_name
          });
          localStorage.setItem(LOCAL_STORAGE_KEY.PAYMENT_STATUS, "paid");
          notify("お支払いが完了しました", NotifyTypeEnum.SUCCESS);
          paymentQuery.refetch();
        } catch (err) {
          notify("error", NotifyTypeEnum.ERROR);
        }
      }
      setLoading(false);
    })(event);
  };

  useEffect(() => {
    const subscription = watch((value) => {
      setTotalWatch(value.total_account || null);
      setPlanWatch(value.plan || "");
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (paymentData) {
      setValue("card_name", paymentData?.payment_plan?.stripe_customer_name);
      setValue("plan", paymentData?.payment_plan?.plan_name);
      setValue(
        "total_account",
        paymentData?.payment_plan?.staffs_amount?.toString()
      );
      setValue(
        "subscription_id",
        paymentData?.payment_plan?.stripe_subscription_id
      );
    } else {
      setValue("plan", company?.plan_payment);
    }
  }, [paymentData, company]);

  return (
    <Container>
      <MenuHeader
        headerLabel={"お支払い"}
        leftLabel={"戻る"}
        leftLink={() => {
          navigate(ROUTE?.MYPAGE?.INDEX);
          queryClient.removeQueries(["isEditPayment"]);
        }}
      />
      <form onSubmit={onFormSubmit} className="w-full">
        <FormSelectFieldCustom
          label="加入プラン"
          subLabel="（1アカウントを含む）"
          containerClassName="!mt-4"
          subLabelClassName="text-sm"
          labelClassName="text-base"
          inputContainerClassName="w-fit !mt-2"
          inputClassName="mr-5 focus:bg-[#F2F2F2]"
          arrowDownIcon={IconTinyArrowDown}
          register={register("plan", {
            required: "加入プランが必須です。"
          })}
          watch={watch}
          errors={errors.plan}
          options={PLAN_LIST}
          isShowNone={false}
          isRequired={true}
        />

        {!isLoading && (
          <FormSelectFieldCustom
            label="追加アカウント数"
            containerClassName="!mt-4"
            labelClassName="text-base"
            inputContainerClassName="w-fit !mt-2 w-[100px]"
            inputClassName="mr-5 focus:bg-[#F2F2F2]"
            arrowDownIcon={IconTinyArrowDown}
            register={register("total_account", {
              required: "追加アカウント数が必須です。"
            })}
            errors={errors.total_account}
            options={Array.from({ length: 100 }, (_, index) => index + 1)
              .filter((value) => value > (company?.staffs_amount || 0))
              .map((value) => ({
                label: value.toString(),
                value: value
              }))}
            isShowNone={false}
            isRequired={true}
          />
        )}
        <div className="w-full p-4 mt-8 ">
          <div className="flex flex-col gap-4 bg-[#EFEFEF] p-4 border border-[#B0B0B0]">
            <div className="flex flex-col gap-1">
              <label className="text-[15px]">プラン（1アカウントを含む）</label>
              <span className="text-bold text-[22px]">
                ¥
                {formatCurrencyJa(
                  planWatch === PLAN_TYPE?.ratio
                    ? PRICE?.ratio
                    : PRICE?.standard
                )}
                /月
              </span>
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-[15px]">追加アカウント</label>
              <span className="text-bold text-[22px]">
                ¥{formatCurrencyJa(PRICE?.user)}/月
              </span>
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-[15px]">合計</label>
              <div className="flex justify-start items-end gap-1">
                <span className="text-bold text-[22px]">
                  ¥
                  {planWatch === PLAN_TYPE?.ratio
                    ? formatCurrencyJa(
                        PRICE?.ratio + PRICE?.user * Number(totalWatch)
                      )
                    : formatCurrencyJa(
                        PRICE?.standard + PRICE?.user * Number(totalWatch)
                      )}
                  /月{" "}
                </span>{" "}
                <span className="text-sm font-normal">（+消費税）</span>
              </div>
            </div>
          </div>
        </div>
        <div className="whitespace-pre-line mb-8 text-[13px] color-[#333333] flex items-center justify-center mt-2">{`※一ヶ月以内にプラン料金をお支払い済みの場合、次回ご請求より上記金額でのお支払いとなります。追加アカウントの増加分は日割り計算の上、次回ご請求に加算されます。`}</div>
        <div className="flex flex-col gap-6">
          <div className="w-full flex flex-col gap-2">
            <label>
              カード番号{" "}
              {(!isEditPayment || isCardNumberComplete) && (
                <span className="text-[#FF0000]">*</span>
              )}
            </label>

            <div className="border focus:border-2 border-[#E3E3E3] rounded-[4px] p-3 bg-[#f9f9f9]">
              <CardNumberElement
                options={cardElementOptions}
                onChange={handleCardNumberChange}
              />
            </div>
            {cardError && <p className="text-red-500 text-xs">{cardError}</p>}
          </div>

          <div className="w-full flex flex-col gap-2">
            <label>
              有効期限{" "}
              {(!isEditPayment || isCardNumberComplete) && (
                <span className="text-[#FF0000]">*</span>
              )}
            </label>

            <div className="border focus:border-2 border-[#E3E3E3] rounded-[4px] p-3 bg-[#f9f9f9]">
              <CardExpiryElement
                options={cardElementOptions}
                onChange={handleExpiryChange}
              />
            </div>
            {expiryError && (
              <p className="text-red-500 text-xs">{expiryError}</p>
            )}
          </div>

          <div className="w-full flex flex-col gap-2">
            <label>
              セキュリティコード{" "}
              {(!isEditPayment || isCardNumberComplete) && (
                <span className="text-[#FF0000]">*</span>
              )}
            </label>

            <div className="border focus:border-2 border-[#E3E3E3] rounded-[4px] p-3 bg-[#f9f9f9]">
              <CardCvcElement
                options={cardElementOptions}
                onChange={handleCvcChange}
              />
            </div>
            {cvcError && <p className="text-red-500 text-xs">{cvcError}</p>}
          </div>

          <FormTextField
            label="カード名義"
            labelClassName="text-base"
            inputContainerClassName="mb-4"
            inputClassName="mt-1 !outline-none"
            // isRequired={true}
            register={register("card_name", {
              // required: "カード名義が必須です。",
              onBlur: (e) => {
                const { name, value } = e.target;
                setValue(name, value.trim());
              }
            })}
            errors={errors.card_name}
          />
        </div>

        <button
          className="w-full border-none p-3 bg-[#525252] text-white mt-6 mb-10"
          type="submit"
          disabled={loading}
        >
          支払う
        </button>
      </form>
      {loading && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
          <Loading></Loading>
        </div>
      )}
    </Container>
  );
};

const cardElementOptions = {
  style: {
    base: {
      "fontSize": "16px",
      "fontFamily": "Roboto, Arial, sans-serif",
      "color": "#424770",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#fa755a"
    }
  }
};

export default PaymentAdd;
